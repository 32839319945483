/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef } from "react";
import ReactMarkdown from "react-markdown";

import { cssFns } from "@superweb/css";
import { icons, useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import { useMessage } from "#internal/intl";

import { useBrand } from "../router";
import { useLogAction } from "../analytics";

import YandexOfferAvif from "../images/yandex-offer.avif";
import YandexOfferPng from "../images/yandex-offer.png";
import YandexOfferWebp from "../images/yandex-offer.webp";
import YangoOfferAvif from "../images/offer.avif";
import YangoOfferPng from "../images/offer.png";
import YangoOfferWebp from "../images/offer.webp";
import YandexDeliveryOfferAvif from "../images/yandex-delivery-offer.avif";
import YandexDeliveryOfferPng from "../images/yandex-delivery-offer.png";
import YandexDeliveryOfferWebp from "../images/yandex-delivery-offer.webp";
import YangoDeliveryOfferAvif from "../images/yango-delivery-offer.avif";
import YangoDeliveryOfferPng from "../images/yango-delivery-offer.png";
import YangoDeliveryOfferWebp from "../images/yango-delivery-offer.webp";

import { LinkButton } from "./link-button";

type Stage = "main" | "vehicle" | "license" | "download_app" | "archive";

export const Offer = ({
  offerText,
  isDelivery,
  phone,
  messageLink,
  stage,
}: {
  offerText?: string;
  isDelivery: boolean;
  phone?: string;
  messageLink?: string;
  stage: Stage;
}) => {
  const message = useMessage();
  const uiColors = useUiColors();
  const typo = useTypo();
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const brand = useBrand();
  const logAction = useLogAction("contact");

  const phoneNumber = phone?.slice(1);

  const yangoBrand = brand === "yango";

  const imageSrc = yangoBrand
    ? isDelivery
      ? {
          avif: YangoDeliveryOfferAvif,
          png: YangoDeliveryOfferPng,
          webp: YangoDeliveryOfferWebp,
        }
      : {
          avif: YangoOfferAvif,
          png: YangoOfferPng,
          webp: YangoOfferWebp,
        }
    : isDelivery
      ? {
          avif: YandexDeliveryOfferAvif,
          png: YandexDeliveryOfferPng,
          webp: YandexDeliveryOfferWebp,
        }
      : {
          avif: YandexOfferAvif,
          png: YandexOfferPng,
          webp: YandexOfferWebp,
        };

  return (
    <div
      ref={ref}
      css={{
        display: "grid",
        gridTemplateColumns: isMobile ? undefined : "1fr auto",
        backgroundColor: uiColors.controlMain,
        ...cssFns.border({ radius: "20px" }),
        ...cssFns.padding("16px"),
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateRows: offerText ? "1fr auto" : "1fr",
          rowGap: "8px",
          color: yangoBrand ? uiColors.everFront : uiColors.textOnControlMain,
          ...typo({
            level: "body1",
            weight: "medium",
            density: "normal",
          }),
        }}
      >
        {offerText && (
          <ReactMarkdown
            children={offerText.replace(/([^\n])\n([^\n])/g, "$1\n\n$2")}
            components={{
              ul: ({ children }) => (
                <ul
                  css={{
                    paddingInlineStart: "30px",
                    ...cssFns.margin("0"),
                  }}
                >
                  {children}
                </ul>
              ),
              p: ({ children }) => <p css={cssFns.margin("0")}>{children}</p>,
            }}
            allowedElements={["ul", "li", "p"]}
            unwrapDisallowed
          />
        )}
        {(phone || messageLink) && (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginInlineStart: "-8px",
              marginBlockEnd: "-4px",
            }}
          >
            {phone && (
              <LinkButton
                link={{ href: `tel:${phoneNumber}` }}
                icon={(props) => <icons.Call {...props} />}
                text={phone}
                onPress={() =>
                  logAction("phone_click", { onlyYM: true, params: { stage } })
                }
              />
            )}
            {messageLink && (
              <LinkButton
                link={{
                  href: messageLink,
                  external: true,
                }}
                icon={(props) => <icons.ChatOutline {...props} />}
                text={message({
                  id: "865208d2-2a7b-4a25-98a2-0c5264c8d8ba",
                  context: "Yango forms. Contactor deatails. Messanger",
                  default: "Messenger",
                })}
                onPress={() =>
                  logAction("messenger_click", {
                    onlyYM: true,
                    params: { stage },
                  })
                }
              />
            )}
          </div>
        )}
      </div>
      {isMobile ? null : (
        <div
          css={{
            ...cssFns.overflow("hidden"),
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <picture>
            <source src={imageSrc.avif} type="image/avif" />
            <source src={imageSrc.webp} type="image/webp" />
            <img
              css={{
                display: "flex",
                maxWidth: "100%",
                transform: "translateZ(0)",
                objectFit: "contain",
                objectPosition: "center",
                width: isDelivery ? "166px" : "296px",
                height: "132px",
              }}
              width={1161}
              height={521}
              src={imageSrc.png}
              alt={message({
                id: "7e347b54-2aaf-4ba5-bed5-89f34e88a4c3",
                context: "Yango forms. Offer. Image",
                default: "Offer",
              })}
            />
          </picture>
        </div>
      )}
    </div>
  );
};
