/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, useMemo, type ReactNode } from "react";

export type UiOptionsValue = {
  /** Options for enabling and disabling experimental or testing features */
  experimental?: {
    /**
     * If enabled, popover element e.g. listbox in Select or menu in MenuButton
     * will be rendered inside AppContainer instead of document.body.
     */
    renderPopoversInsideAppContainer?: boolean;

    /**
     * If enabled, Field's label placeholder will be extended on focus (when value is entered) to fill the gap.
     */
    extendFieldLabelOnFocus?: boolean;

    /**
     * If enabled, the page will have a bottom padding.
     */
    enabledPagePaddingBottom?: boolean;

    /**
     * If enabled, tooltip reference element will be child of cell (not cell)
     */
    newTableTooltipRef?: boolean;

    /**
     * If true, the input field expands to occupy the space of the clear button when not focused.
     */
    enableInputAtClear?: boolean;

    /**
     * If true, component with scroll list will be use custom scrollbar.
     */
    enableCustomScrollbarInComponent?: boolean;

    /**
     * If true, the components use variable font.
     */
    enableVariableFont?: boolean;
  };
};

const defaultValue: UiOptionsValue = {
  experimental: {},
};

const context = createContext<UiOptionsValue>(defaultValue);

/**
 * Allows to set options for all components from `@superweb/ui`
 */
export const UiOptions = ({
  value,
  children,
}: {
  value: UiOptionsValue;
  children: ReactNode;
}) => {
  const nextValue = useMemo(() => {
    return {
      ...defaultValue,
      ...value,
      experimental: {
        ...defaultValue.experimental,
        ...value.experimental,
      },
    };
  }, [value]);

  return <context.Provider value={nextValue}>{children}</context.Provider>;
};
/**
 * @internal
 * Hook for get options
 */
export const useUiOptions = () => {
  return useContext(context);
};
