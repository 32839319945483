/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  type Key,
  type ReactElement,
  type ReactNode,
  type RefObject,
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import {
  DismissButton,
  Overlay,
  type AriaMenuProps,
  type AriaPopoverProps,
  useFocusRing,
  useHover,
  useMenuTrigger,
  useMenu,
  useMenuItem,
  useMenuSection,
  usePopover,
  useSeparator,
  mergeProps,
} from "react-aria";
import {
  Item,
  Section,
  type OverlayTriggerState,
  type TreeState,
  type Node,
  useMenuTriggerState,
  useTreeState,
} from "react-stately";

import { cssFns, type Style } from "@superweb/css";
import {
  useUiColors,
  useUiShadows,
  useIsMobile,
  useTypo,
  icons,
  useCustomScrollbar,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { UserToolbarButton } from "./button";
import { Search } from "./search";

const MENU_MARGIN = 8;
const SUB_MENU_WIDTH = 230;

const MaxHeightContext = createContext<string | number | undefined>(undefined);

type MenuButtonItem = {
  type: "button";
  label: string;
  description?: string;
  icon?: ReactElement;
  onPress: () => void;
};

type CheckboxMenuItem = {
  type: "checkbox";
  label: string;
  description?: string;
  icon?: ReactElement;
  selected?: boolean;
  onPress: () => void;
};

type MenuButtonSubmenu = {
  type: "submenu";
  label: string;
  description?: string;
  icon?: ReactElement;
  items: (CheckboxMenuItem | false | null | undefined)[];
};

type MenuButtonGroup = {
  type: "group";
  items: (MenuButtonItem | MenuButtonSubmenu | false | null | undefined)[];
};

type CheckboxMenuGroup = {
  type: "checkboxGroup";
  items: (CheckboxMenuItem | false | null | undefined)[];
};

export const UserToolbarMenuButton = ({
  label,
  icon,
  description,
  items,
  searchParams,
  onOpenChange,
}: {
  label?: string;
  icon?: ReactNode;
  description?: string;
  items: (MenuButtonGroup | CheckboxMenuGroup | false | null | undefined)[];
  searchParams?: {
    searchable: boolean;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
  };

  onOpenChange?: (isOpen: boolean) => void;
}) => {
  const state = useMenuTriggerState({ onOpenChange });

  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const contentRef = useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<ReactElement>(
    {},
    state,
    buttonRef,
  );
  const [openSubmenuKey, setOpenSubmenuKey] = useState<string>();

  const onActions = new Map<string, () => void>();

  items.forEach((elem, index) => {
    if (elem) {
      elem.items.forEach((groupElem, fromGroupIndex) => {
        if (groupElem) {
          const itemKey = index + " section: " + fromGroupIndex;

          switch (groupElem.type) {
            case "button":
            case "checkbox": {
              onActions.set(itemKey, () => {
                groupElem.onPress();
                state.close();
              });
              break;
            }
            case "submenu": {
              onActions.set(itemKey, () => {
                if (openSubmenuKey === itemKey) {
                  setOpenSubmenuKey(undefined);
                } else {
                  setOpenSubmenuKey(itemKey);
                }
              });
              break;
            }
          }
        }
      });
    }
  });

  useEffect(() => {
    if (!state.isOpen) {
      searchParams?.onChange("");
      setOpenSubmenuKey(undefined);
    }
  }, [state.isOpen, searchParams]);

  const onAction = (key: Key) => {
    onActions.get(String(key))?.();
  };

  return (
    <>
      <UserToolbarButton
        label={label}
        icon={icon}
        description={description}
        buttonRef={buttonRef}
        externalAriaProps={menuTriggerProps}
      />
      {state.isOpen && (
        <Popover state={state} triggerRef={buttonRef} placement="bottom start">
          <Menu
            {...menuProps}
            menuRef={menuRef}
            onAction={onAction}
            searchParams={searchParams}
            autoFocus={state.focusStrategy ? state.focusStrategy : false}
            onClose={() => {}}
            selectionMode="none"
          >
            {items.flatMap((item, index) => {
              if (!item) {
                return [];
              }

              return (
                <Section key={index}>
                  {item.items.flatMap((sectionItem, itemIndex) => {
                    if (!sectionItem) {
                      return [];
                    }

                    const itemKey = index + " section: " + itemIndex;

                    switch (sectionItem.type) {
                      case "button": {
                        return (
                          <Item key={itemKey} textValue={sectionItem.label}>
                            <MenuItemContent
                              contentRef={contentRef}
                              label={sectionItem.label}
                              description={sectionItem.description}
                              icon={sectionItem.icon}
                            />
                          </Item>
                        );
                      }
                      case "checkbox": {
                        return (
                          <Item key={itemKey} textValue={sectionItem.label}>
                            <MenuItemContent
                              contentRef={contentRef}
                              label={sectionItem.label}
                              description={sectionItem.description}
                              icon={sectionItem.icon}
                              additionalIcon={
                                sectionItem.selected ? (
                                  <icons.Check />
                                ) : undefined
                              }
                            />
                          </Item>
                        );
                      }
                      case "submenu": {
                        return (
                          <Item key={itemKey} textValue={sectionItem.label}>
                            <MenuItemContentSubmenu
                              label={sectionItem.label}
                              description={sectionItem.description}
                              icon={sectionItem.icon}
                              items={sectionItem.items}
                              buttonRef={buttonRef}
                              menuRef={menuRef}
                              open={openSubmenuKey === itemKey}
                              onClose={() => {
                                setOpenSubmenuKey(undefined);
                                state.close();
                              }}
                            />
                          </Item>
                        );
                      }
                    }
                  })}
                </Section>
              );
            })}
          </Menu>
        </Popover>
      )}
    </>
  );
};

const Popover = (
  props: {
    children: ReactNode;
    state: OverlayTriggerState;
  } & Omit<AriaPopoverProps, "popoverRef">,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const { state, children } = props;
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state,
  );
  return (
    <Overlay>
      <div
        {...underlayProps}
        css={{
          position: "fixed",
          ...cssFns.inset("0"),
        }}
      />
      <div
        {...popoverProps}
        ref={ref}
        css={{
          ...cssFns.margin("8px", "0"),
        }}
      >
        <DismissButton onDismiss={state.close} />
        <MaxHeightContext.Provider value={popoverProps.style?.maxHeight}>
          {children}
        </MaxHeightContext.Provider>
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

const Menu = (
  props: {
    disabledItems?: Set<Key>;
    menuRef: RefObject<HTMLDivElement>;
    searchParams?: {
      searchable: boolean;
      placeholder: string;
      value: string;
      onChange: (value: string) => void;
    };
  } & AriaMenuProps<ReactElement>,
) => {
  const menuListRef = useRef<HTMLUListElement>(null);
  const state = useTreeState({ ...props });
  const { menuProps } = useMenu(props, state, menuListRef);

  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const customScrollbarCSS = useCustomScrollbar();

  const maxHeight = useContext(MaxHeightContext);

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  useEffect(() => {
    const handler = (event: TouchEvent) => {
      const closestMenuItem = (event.target as HTMLElement).closest(
        "[role=menuitem]",
      );
      if (
        event.cancelable &&
        typeof closestMenuItem?.getAttribute("href") !== "string"
      ) {
        event.preventDefault();
      }
    };

    const menuElement = menuListRef.current;
    menuElement?.addEventListener("touchend", handler);

    return () => {
      menuElement?.removeEventListener("touchend", handler);
    };
  }, [menuListRef]);

  return (
    <div
      ref={props.menuRef}
      css={{
        minWidth: "160px",
        maxWidth: "350px",
        maxHeight: maxHeight + "px",
        backgroundColor: uiColors.background,
        boxShadow: uiShadows.bottomNormal,
        ...cssFns.border({
          radius: "10px",
        }),
        overflowY: "auto",
      }}
      {...customScrollbarCSS}
    >
      {props.searchParams?.searchable && (
        <div
          css={{
            display: "grid",
            ...cssFns.padding("8px"),
          }}
        >
          <Search
            placeholder={props.searchParams.placeholder}
            value={props.searchParams.value}
            onChange={(value) => {
              props.searchParams?.onChange(value);
            }}
          />
        </div>
      )}

      <ul
        {...menuProps}
        ref={menuListRef}
        css={{
          ...cssFns.margin("0"),
          ...cssFns.padding("0"),
          listStyleType: "none",
        }}
      >
        {[...state.collection].map((item, index) => {
          if (item.type === "section") {
            return (
              <MenuItemSection
                key={item.key}
                section={item}
                state={state}
                sectionIndex={
                  index === [...state.collection].length - 1
                    ? "last"
                    : undefined
                }
              />
            );
          }
          return (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              radiusTop={index === 0}
              radiusBottom={index === [...state.collection].length - 1}
            />
          );
        })}
      </ul>
    </div>
  );
};

const MenuItemSection = ({
  section,
  state,
  sectionIndex,
}: {
  section: Node<ReactElement>;
  state: TreeState<ReactElement>;
  sectionIndex: "first" | "last" | undefined;
}) => {
  const { itemProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });
  const { separatorProps } = useSeparator({
    elementType: "li",
  });
  const uiColors = useUiColors();
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li
          {...separatorProps}
          css={{
            ...cssFns.border({
              width: "0.5px",
              style: "solid",
              color: uiColors.line,
            }),
            opacity: "0.5",
          }}
        />
      )}
      <li {...itemProps}>
        <ul {...groupProps} css={{ listStyleType: "none", paddingLeft: "0" }}>
          {[...section.props.children].map((item, index) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              radiusTop={
                !section.props.title && sectionIndex === "first" && index === 0
              }
              radiusBottom={
                sectionIndex === "last" &&
                index === [...section.props.children].length - 1
              }
            />
          ))}
        </ul>
      </li>
    </>
  );
};

const MenuItem = ({
  item,
  state,
  radiusTop = false,
  radiusBottom = false,
}: {
  item: Node<ReactElement>;
  state: TreeState<ReactElement>;
  radiusTop?: boolean;
  radiusBottom?: boolean;
}) => {
  const ref = useRef(null);
  const { menuItemProps, isPressed } = useMenuItem(
    {
      key: item.key,
    },
    state,
    ref,
  );
  const { hoverProps, isHovered } = useHover({});

  const { isFocusVisible, focusProps } = useFocusRing();

  const uiColors = useUiColors();
  const backgroundColor =
    isPressed || isHovered || isFocusVisible
      ? isPressed
        ? uiColors.press
        : uiColors.hover
      : uiColors.background;

  const liStyle: Style = {
    backgroundColor: backgroundColor,
    outlineStyle: "none",
    cursor: "pointer",
    borderTopLeftRadius: radiusTop ? "10px" : "0px",
    borderTopRightRadius: radiusTop ? "10px" : "0px",
    borderBottomLeftRadius: radiusBottom ? "10px" : "0px",
    borderBottomRightRadius: radiusBottom ? "10px" : "0px",
    transitionDuration: "0.3s",
    color: uiColors.text,
  };

  return (
    <li
      {...mergeProps(menuItemProps, hoverProps, focusProps)}
      ref={ref}
      css={liStyle}
    >
      {item.rendered || item.props.children}
    </li>
  );
};

const MenuItemContentSubmenu = ({
  label,
  description,
  icon,
  items,
  buttonRef,
  menuRef,
  open,
  onClose,
}: {
  label: string;
  description?: string;
  icon?: ReactElement;
  items: (MenuButtonItem | CheckboxMenuItem | false | null | undefined)[];
  buttonRef: RefObject<HTMLElement>;
  menuRef: RefObject<HTMLElement>;
  open: boolean;
  onClose: () => void;
}) => {
  const uiColors = useUiColors();
  const { textInfo } = useLocale();
  const isMobile = useIsMobile();

  const contentRef = useRef<HTMLDivElement>(null);

  const buttonElement = buttonRef.current;
  const menuElement = menuRef.current;
  const contentElement = contentRef.current;

  const positionCss = useMemo(() => {
    if (!open) return {};

    if (!contentElement || !buttonElement || !menuElement) return {};

    const css: Style = { position: "absolute" };

    // Horizontal orientation
    const triggerWidth = Number(contentElement.clientWidth),
      triggerRight = contentElement.getBoundingClientRect().right,
      documentWidth = document.documentElement.clientWidth;

    if (documentWidth - triggerRight > SUB_MENU_WIDTH) {
      // There is enough space to the right of the menu => Submenu on right
      css["left"] = isMobile ? "50px" : `${triggerWidth + MENU_MARGIN}px`;
    } else {
      // There is not enough space to the right of the menu => Submenu on left
      css["left"] = isMobile ? "-50px" : `-${SUB_MENU_WIDTH + MENU_MARGIN}px`;
    }

    // Vertical orientation
    const triggerHight = Number(contentElement.clientHeight),
      subMenuHight = items.length * triggerHight;

    if (
      buttonElement.getBoundingClientRect().top <
      contentElement.getBoundingClientRect().top
    ) {
      // Menu at the bottom of the button

      const shift = contentElement.offsetTop;
      const scrollPosition = menuElement.scrollTop;
      let topPosition =
        shift - subMenuHight / 2 + triggerHight / 2 - scrollPosition;

      //Processing when the submenu goes off the screen
      const menuRect = menuElement.getBoundingClientRect();
      const offScreen =
        menuRect.top + topPosition + subMenuHight - window.innerHeight;
      if (offScreen > 0) {
        topPosition = topPosition - offScreen;
      }

      css["top"] = `${topPosition > 0 ? topPosition : 0}px`;
    } else {
      // Menu at the top of the button

      if (isMobile) {
        css["bottom"] = "0";
      } else {
        const shift =
          buttonElement.getBoundingClientRect().top -
          contentElement.getBoundingClientRect().bottom -
          MENU_MARGIN;
        const bottomPosition = shift - subMenuHight / 2 + triggerHight / 2;

        css["bottom"] = `${bottomPosition > 0 ? bottomPosition : 0}px`;
      }
    }

    return css;
  }, [
    contentElement,
    buttonElement,
    menuElement,
    open,
    items.length,
    isMobile,
  ]);

  return (
    <>
      <MenuItemContent
        label={label}
        description={description}
        icon={icon}
        additionalIcon={
          <div css={{ color: uiColors.textMinor }}>
            {textInfo.direction === "rtl" ? (
              <icons.ChevronLeft />
            ) : (
              <icons.ChevronRight />
            )}
          </div>
        }
        contentRef={contentRef}
      />
      {open && (
        <div css={positionCss}>
          <SubMenu items={items} onClick={onClose} />
        </div>
      )}
    </>
  );
};

const SubMenu = ({
  items,
  onClick,
}: {
  items: (MenuButtonItem | CheckboxMenuItem | false | null | undefined)[];
  onClick: () => void;
}) => {
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const customScrollbarCSS = useCustomScrollbar();

  const maxHeight = useContext(MaxHeightContext);

  return (
    <ul
      css={{
        width: `${SUB_MENU_WIDTH}px`,
        ...cssFns.padding("0"),
        listStyleType: "none",
        backgroundColor: uiColors.background,
        boxShadow: uiShadows.bottomNormal,
        ...cssFns.border({
          radius: "10px",
        }),
        overflowY: "auto",
        maxHeight: maxHeight + "px",
      }}
      {...customScrollbarCSS}
    >
      {items.flatMap((item, index) => {
        if (!item) return;
        return <SubMenuItem key={index} item={item} onClick={onClick} />;
      })}
    </ul>
  );
};

const SubMenuItem = ({
  item,
  onClick,
}: {
  item: MenuButtonItem | CheckboxMenuItem;
  onClick: () => void;
}) => {
  const contentRef = useRef(null);

  const { hoverProps, isHovered } = useHover({});
  const { isFocusVisible, focusProps } = useFocusRing();

  const uiColors = useUiColors();
  const backgroundColor =
    isHovered || isFocusVisible
      ? cssFns.blend(
          cssFns.transparentize(uiColors.background, 1),
          cssFns.transparentize(uiColors.everBack, 0.9),
        )
      : cssFns.transparentize(uiColors.background, 1);

  return (
    <li
      role="menuitem"
      {...hoverProps}
      {...focusProps}
      css={{
        backgroundColor: backgroundColor,
        outlineStyle: "none",
        cursor: "pointer",
        transitionDuration: "0.3s",
        color: uiColors.text,
      }}
      onPointerUp={() => {
        onClick();
        item.onPress();
      }}
    >
      <MenuItemContent
        contentRef={contentRef}
        label={item.label}
        description={item.description}
        icon={item.icon}
        additionalIcon={
          item.type === "checkbox" && item.selected ? (
            <icons.Check />
          ) : undefined
        }
      />
    </li>
  );
};

const MenuItemContent = ({
  label,
  description,
  icon,
  additionalIcon,
  contentRef,
}: {
  label?: string;
  description?: string;
  icon?: ReactElement;
  additionalIcon?: ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const ref = useRef<HTMLDivElement>(null);
  const internalContentRef = contentRef || ref;

  return (
    <div
      ref={internalContentRef}
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
        ...cssFns.padding("0px", "16px"),
        gridTemplateRows: "16px [content] auto 16px",
        backgroundColor: "transparent",
        height: "40px",
        ...(icon && { paddingInlineStart: "10px" }),
        ...(additionalIcon && {
          paddingInlineEnd: "10px",
        }),
      }}
    >
      <div
        css={{
          display: "grid",
          alignItems: "center",
          justifyContent: "start",
          gridRowStart: "content",
          gridTemplateRows: "[subcontent] auto",
        }}
      >
        {icon && (
          <div
            css={{
              width: "28px",
              height: "28px",
              display: "grid",
              alignItems: "center",
              justifyContent: "start",
              gridRowStart: "subcontent",
              paddingInlineEnd: "6px",
              color: uiColors.textMinor,
            }}
          >
            {icon}
          </div>
        )}
        {label && (
          <span
            css={{
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "tight",
              }),
              ...cssFns.overflow("hidden"),
              gridRowStart: "subcontent",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
            }}
          >
            {label}
            {description && (
              <span
                css={{
                  ...cssFns.overflow("hidden"),
                  gridRowStart: "subcontent",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: uiColors.textMinor,
                  ...typo({
                    level: "caption2",
                    weight: "regular",
                    density: "tight",
                  }),
                  display: "block",
                }}
              >
                {description}
              </span>
            )}
          </span>
        )}
      </div>
      {additionalIcon ? (
        <div
          css={{
            gridRowStart: "content",
            width: "24px",
            height: "24px",
            paddingInlineStart: "10px",
          }}
        >
          {additionalIcon}
        </div>
      ) : null}
    </div>
  );
};
