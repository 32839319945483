/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useRef,
  createContext,
  useContext,
  type RefObject,
  type ReactNode,
} from "react";
import {
  useFocusRing,
  mergeProps,
  usePopover,
  Overlay,
  useDialog,
  type AriaDialogProps,
} from "react-aria";
import type { OverlayTriggerState } from "react-stately";

import { cssFns, type Style } from "@superweb/css";

import { useDebouncedState } from "./state/state";
import { useIsMobile } from "./mobile-context";
import { useUiOptions } from "./ui-options-context";
import { useUiColors, useUiShadows } from "./theme";

type Placement = "top" | "bottom" | "left" | "right" | "start" | "end";

export const PopoverContext = createContext<
  | {
      container: HTMLDivElement | null;
    }
  | undefined
>(undefined);

export const PopoverContextProvider = PopoverContext.Provider;

export const usePopoverContainer = () => {
  const options = useUiOptions();
  const popoverContext = useContext(PopoverContext);
  if (!options.experimental?.renderPopoversInsideAppContainer) {
    return undefined;
  }
  if (!popoverContext)
    throw new Error("Popover must have an access to PopoverContext");

  return popoverContext.container ?? undefined;
};

export const BasePopover = ({
  ariaLabel,
  overlayState,
  children,
  triggerNode,
  triggerRef,
  overlayProps,
  css = {},
  offset = 0,
  maxHeight,
  placement = "bottom",
}: {
  ariaLabel?: string;
  placement: Placement;
  overlayState: OverlayTriggerState;
  children: ReactNode;
  triggerNode: ReactNode;
  triggerRef: RefObject<HTMLButtonElement>;
  overlayProps: AriaDialogProps;
  css?: Style;
  maxHeight?: number;
  offset?: number;
}) => {
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const isMobile = useIsMobile();

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  // by debouncing the open state
  const debouncedIsOpen = useDebouncedState(overlayState.isOpen, 0);
  // apply debounced state only on mobile in `true` -> `false` phase
  const isOpen = (isMobile && debouncedIsOpen) || overlayState.isOpen;

  const ref = useRef<HTMLDivElement>(null);

  const { popoverProps, underlayProps } = usePopover(
    {
      popoverRef: ref,
      triggerRef,
      offset,
      maxHeight,
      placement,
    },
    overlayState,
  );

  return (
    <>
      {triggerNode}
      {isOpen && (
        <Overlay>
          <div
            {...underlayProps}
            css={{
              position: "fixed",
              ...cssFns.inset("0"),
            }}
          />
          <div
            {...popoverProps}
            ref={ref}
            css={{
              ...cssFns.border({ radius: "13px" }),
              ...cssFns.overflow("hidden"),
              backgroundColor: uiColors.background,
              boxShadow: uiShadows.bottomNormal,
            }}
          >
            <Dialog ariaLabel={ariaLabel} {...overlayProps}>
              <div
                style={{
                  maxHeight: popoverProps.style?.maxHeight,
                }}
                // CSS properties are set for the content block.
                // Because the maximum height of the content is calculated in react-ari.
                css={{
                  ...css,
                }}
              >
                {children}
              </div>
            </Dialog>
          </div>
        </Overlay>
      )}
    </>
  );
};

const Dialog = ({
  ariaLabel,
  children,
  ...overlayProps
}: {
  ariaLabel?: string;
  children: ReactNode;
} & AriaDialogProps) => {
  const { focusProps } = useFocusRing();

  const dialogRef = useRef<HTMLDivElement>(null);

  const { dialogProps } = useDialog(overlayProps, dialogRef);

  return (
    <div
      {...mergeProps(focusProps, dialogProps)}
      ref={dialogRef}
      aria-label={ariaLabel}
      css={{
        outlineStyle: "none",
      }}
    >
      {children}
    </div>
  );
};
