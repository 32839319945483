import type { SVGProps } from "react";

export const YangoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="30" viewBox="0 0 86 30" fill="none" {...props}>
    <path
      d="M9.65702 8.27989C9.58302 9.50089 9.47202 10.9809 9.39802 11.4619C9.58302 11.0179 10.138 9.87089 10.841 8.61289L14.578 1.73089H21.127L9.80502 19.0839L6.84502 28.0009H0.999023L4.10702 18.6399L4.18102 1.73089H9.95302L9.65702 8.27989Z"
      fill="#21201F"
    />
    <path
      d="M22.7955 1.73089H30.6765L26.8655 28.0009H21.2415L22.0555 23.2649H17.3565L15.1365 28.0009H9.17949L22.7955 1.73089ZM19.7245 18.3069H22.9435L24.3865 10.0929C24.6085 8.87189 24.9045 7.42889 24.9785 6.94789C24.7935 7.39189 24.3125 8.57589 23.6835 9.87089L19.7245 18.3069Z"
      fill="#21201F"
    />
    <path
      d="M41.7311 14.6439C41.6571 15.8649 41.5461 17.3449 41.5091 17.8259C41.6571 17.3819 41.9901 16.1609 42.4711 14.7919L46.7631 1.73089H52.1281L43.3961 28.0009H37.1801L37.9941 14.4219C38.0681 13.2009 38.2161 11.7209 38.2531 11.2399C38.1051 11.6839 37.7721 12.9049 37.2911 14.2739L32.7771 28.0009H27.4121L36.1441 1.73089H42.5081L41.7311 14.6439Z"
      fill="#21201F"
    />
    <path
      d="M55.7793 18.0849L57.4073 13.1269H65.3993L60.4413 28.0009H56.2603L56.6303 25.8919C55.3353 27.4829 54.0773 28.1859 52.2643 28.1859C47.0473 28.1859 46.8253 21.1559 49.3043 13.5709C52.0053 5.31989 55.8533 1.21289 60.7003 1.21289C64.9183 1.21289 66.7313 4.28389 66.9533 8.09489L61.7363 9.83389C61.5513 6.83689 60.9963 5.98589 60.1453 5.98589C58.6283 5.98589 57.2963 7.87289 55.1133 14.5329C53.5593 19.2689 52.8563 23.6719 54.7063 23.6719C56.3343 23.6719 57.6663 20.8969 58.4803 18.4179L58.5913 18.0849H55.7793Z"
      fill="#21201F"
    />
    <path
      d="M71.0218 28.4819C63.8808 28.4819 63.8438 21.1559 66.3228 13.5709C68.9868 5.31989 72.9828 1.24989 77.5338 1.24989C84.6748 1.24989 84.7118 8.57589 82.2328 16.1609C79.5688 24.4119 75.5728 28.4819 71.0218 28.4819ZM71.6138 23.7829C72.8718 23.7829 74.2408 21.9699 76.4238 15.1989C77.8668 10.7589 79.1248 5.94889 76.9418 5.94889C75.6838 5.94889 74.3148 7.76189 72.1318 14.5329C70.6888 18.8989 69.3938 23.7829 71.6138 23.7829Z"
      fill="#21201F"
    />
  </svg>
);

export const YandexLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="163" height="31" viewBox="0 0 163 31" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5549 24.9998H12.3199V16.3198H10.1499L5.0049 24.9998H0.524902L6.1949 15.6198C4.5849 15.0831 3.34824 14.2315 2.4849 13.0648C1.62157 11.8748 1.1899 10.3931 1.1899 8.61981C1.1899 7.26647 1.3999 6.09981 1.8199 5.11981C2.2399 4.11647 2.84657 3.28814 3.6399 2.6348C4.43324 1.98147 5.3899 1.49147 6.5099 1.1648C7.6299 0.838138 8.87824 0.674805 10.2549 0.674805H16.5549V24.9998ZM10.2199 3.96481C8.7499 3.96481 7.5949 4.33814 6.7549 5.08481C5.9149 5.83147 5.4949 7.00981 5.4949 8.61981C5.4949 10.1598 5.8799 11.2798 6.6499 11.9798C7.44324 12.6798 8.58657 13.0298 10.0799 13.0298H12.3199V3.96481H10.2199Z"
      fill="#21201F"
    />
    <path
      d="M33.9652 7.39481V24.9998H29.8002V17.6498H24.2352V24.9998H20.0702V7.39481H24.2352V14.3598H29.8002V7.39481H33.9652Z"
      fill="#21201F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.9377 29.0248H49.0877V24.9998H39.7427V29.0248H35.8927V21.7098H37.1877C37.561 20.9865 37.8527 20.1115 38.0627 19.0848C38.296 18.0581 38.471 16.9731 38.5877 15.8298C38.7277 14.6631 38.821 13.4848 38.8677 12.2948C38.9143 11.1048 38.9377 9.97314 38.9377 8.89981V7.39481H51.0827V21.7098H52.9377V29.0248ZM46.9177 10.6848H42.5077V11.2098C42.5077 11.9798 42.4843 12.8315 42.4377 13.7648C42.391 14.6981 42.3093 15.6431 42.1927 16.5998C42.0993 17.5565 41.9477 18.4898 41.7377 19.3998C41.551 20.2865 41.3177 21.0565 41.0377 21.7098H46.9177V10.6848Z"
      fill="#21201F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.641 17.6848C58.711 19.1548 59.0727 20.2515 59.726 20.9748C60.3794 21.6981 61.476 22.0598 63.016 22.0598C64.0427 22.0598 65.046 21.8965 66.026 21.5698C67.0294 21.2431 67.8577 20.8581 68.511 20.4148V23.8098C67.9044 24.2298 67.111 24.5915 66.131 24.8948C65.151 25.1981 63.996 25.3498 62.666 25.3498C59.9827 25.3498 57.9177 24.6031 56.471 23.1098C55.0477 21.6165 54.336 19.3298 54.336 16.2498C54.336 14.9198 54.4877 13.6948 54.791 12.5748C55.0944 11.4548 55.5494 10.4865 56.156 9.66981C56.786 8.82981 57.5677 8.18814 58.501 7.74481C59.4577 7.27814 60.566 7.04481 61.826 7.04481C62.876 7.04481 63.821 7.19647 64.661 7.49981C65.501 7.80314 66.2127 8.29314 66.796 8.96981C67.4027 9.64647 67.8694 10.5215 68.196 11.5948C68.5227 12.6448 68.686 13.9398 68.686 15.4798V17.6848H58.641ZM61.756 10.3348C60.7527 10.3348 59.9944 10.6965 59.481 11.4198C58.9677 12.1431 58.6877 13.2048 58.641 14.6048H64.451V14.2548C64.451 13.6948 64.4044 13.1815 64.311 12.7148C64.241 12.2248 64.101 11.8048 63.891 11.4548C63.681 11.1048 63.401 10.8365 63.051 10.6498C62.701 10.4398 62.2694 10.3348 61.756 10.3348Z"
      fill="#21201F"
    />
    <path
      d="M75.6415 16.4948V24.9998H71.4765V7.39481H75.6415V15.6548L81.5215 7.39481H85.7215L79.8415 15.6548L86.5265 24.9998H81.8015L75.6415 16.4948Z"
      fill="#21201F"
    />
    <path
      d="M96.0238 25.3498C93.2238 25.3498 91.1471 24.5681 89.7938 23.0048C88.4404 21.4415 87.7638 19.1781 87.7638 16.2148C87.7638 14.8848 87.9271 13.6598 88.2538 12.5398C88.6038 11.3965 89.1171 10.4281 89.7938 9.63481C90.4938 8.81814 91.3454 8.18814 92.3488 7.74481C93.3521 7.27814 94.5188 7.04481 95.8488 7.04481C96.8988 7.04481 97.7971 7.12647 98.5438 7.2898C99.2904 7.42981 99.8854 7.62814 100.329 7.88481V11.4198C99.9088 11.1631 99.3371 10.9181 98.6138 10.6848C97.8904 10.4515 97.1204 10.3348 96.3038 10.3348C94.9271 10.3348 93.8771 10.8248 93.1538 11.8048C92.4304 12.7615 92.0688 14.1965 92.0688 16.1098C92.0688 17.9298 92.3954 19.3765 93.0488 20.4498C93.7021 21.5231 94.7754 22.0598 96.2688 22.0598C97.1554 22.0598 97.9138 21.9548 98.5438 21.7448C99.1971 21.5348 99.7921 21.2431 100.329 20.8698V24.2648C99.3254 24.9881 97.8904 25.3498 96.0238 25.3498Z"
      fill="#21201F"
    />
    <path
      d="M126.164 0.674805V24.9998H121.929V3.96481H114.369V24.9998H110.134V0.674805H126.164Z"
      fill="#21201F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.71 9.66981C134.247 8.80647 134.912 8.15314 135.705 7.70981C136.522 7.26647 137.42 7.04481 138.4 7.04481C139.38 7.04481 140.279 7.24314 141.095 7.63981C141.912 8.03647 142.612 8.63147 143.195 9.42481C143.779 10.1948 144.234 11.1515 144.56 12.2948C144.887 13.4148 145.05 14.7098 145.05 16.1798C145.05 17.6498 144.875 18.9565 144.525 20.0998C144.199 21.2431 143.732 22.2115 143.125 23.0048C142.542 23.7748 141.819 24.3581 140.955 24.7548C140.115 25.1515 139.194 25.3498 138.19 25.3498C136.347 25.3498 134.889 24.5448 133.815 22.9348V30.0748H129.65V7.39481H133.5L133.71 9.66981ZM137.14 22.0598C138.354 22.0598 139.252 21.5931 139.835 20.6598C140.442 19.7265 140.745 18.2331 140.745 16.1798C140.745 14.1265 140.465 12.6448 139.905 11.7348C139.345 10.8015 138.494 10.3348 137.35 10.3348C136.184 10.3348 135.309 10.7781 134.725 11.6648C134.142 12.5515 133.839 13.9748 133.815 15.9348V16.2148C133.815 18.2681 134.084 19.7615 134.62 20.6948C135.18 21.6048 136.02 22.0598 137.14 22.0598Z"
      fill="#21201F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.904 7.04481C156.047 7.04481 157.097 7.24314 158.054 7.63981C159.011 8.03647 159.827 8.63147 160.504 9.42481C161.181 10.1948 161.706 11.1515 162.079 12.2948C162.476 13.4148 162.674 14.7098 162.674 16.1798C162.674 17.6498 162.476 18.9565 162.079 20.0998C161.706 21.2431 161.181 22.2115 160.504 23.0048C159.827 23.7748 159.011 24.3581 158.054 24.7548C157.097 25.1515 156.047 25.3498 154.904 25.3498C153.761 25.3498 152.711 25.1515 151.754 24.7548C150.797 24.3581 149.981 23.7748 149.304 23.0048C148.627 22.2115 148.091 21.2548 147.694 20.1348C147.321 18.9915 147.134 17.6848 147.134 16.2148C147.134 14.7448 147.321 13.4381 147.694 12.2948C148.091 11.1515 148.627 10.1948 149.304 9.42481C149.981 8.63147 150.797 8.03647 151.754 7.63981C152.711 7.24314 153.761 7.04481 154.904 7.04481ZM154.904 22.0598C156.071 22.0598 156.934 21.5931 157.494 20.6598C158.077 19.7265 158.369 18.2331 158.369 16.1798C158.369 14.1265 158.077 12.6448 157.494 11.7348C156.934 10.8015 156.071 10.3348 154.904 10.3348C153.737 10.3348 152.862 10.8015 152.279 11.7348C151.719 12.6681 151.439 14.1615 151.439 16.2148C151.439 18.2681 151.719 19.7615 152.279 20.6948C152.862 21.6048 153.737 22.0598 154.904 22.0598Z"
      fill="#21201F"
    />
  </svg>
);

export const YandexIntLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="164" height="28" viewBox="0 0 164 28" fill="none" {...props}>
    <path
      fill="#21201F"
      d="m6.9 1.675 4.97 10.185 5.005-10.185h3.955l-7.105 13.93V26H9.49V15.605L2.35 1.675H6.9Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M36.085 21.695c0 1.447.07 2.881.21 4.305h-4.06a7.655 7.655 0 0 1-.21-.665 9.251 9.251 0 0 1-.105-.735h-.175c-.397.42-.91.805-1.54 1.155-.63.326-1.552.49-2.765.49-1.61 0-2.917-.479-3.92-1.435-.98-.957-1.47-2.333-1.47-4.13 0-1.797.735-3.185 2.205-4.165 1.47-1.004 3.803-1.505 7-1.505h.665v-.98c0-.957-.233-1.622-.7-1.995-.467-.397-1.202-.595-2.205-.595a8.05 8.05 0 0 0-1.645.175c-.56.093-1.097.221-1.61.385-.49.163-.957.35-1.4.56-.42.21-.77.42-1.05.63V9.725c.63-.374 1.47-.735 2.52-1.085s2.275-.525 3.675-.525c2.217 0 3.862.443 4.935 1.33 1.097.886 1.645 2.403 1.645 4.55v7.7Zm-4.165-3.71h-.595c-1.797 0-3.08.21-3.85.63-.77.397-1.155 1.097-1.155 2.1 0 .747.21 1.353.63 1.82.443.443 1.108.665 1.995.665.747 0 1.365-.128 1.855-.385.49-.28.863-.619 1.12-1.015v-3.815Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M43.599 26h-4.165V8.395h4.06l.105 1.47h.21c.42-.42 1.003-.817 1.75-1.19.77-.374 1.785-.56 3.045-.56 1.703 0 2.928.408 3.675 1.225.746.793 1.12 2.065 1.12 3.815V26h-4.165V13.575c0-1.307-.712-1.96-2.135-1.96-.747 0-1.424.175-2.03.525a5.872 5.872 0 0 0-1.47 1.225V26Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M67.369 23.725c-1.144 1.75-2.707 2.625-4.69 2.625-.98 0-1.879-.199-2.695-.595a5.79 5.79 0 0 1-2.1-1.75c-.584-.77-1.039-1.715-1.365-2.835-.327-1.12-.49-2.404-.49-3.85 0-1.47.163-2.777.49-3.92.35-1.167.816-2.135 1.4-2.905a6.213 6.213 0 0 1 2.17-1.82c.84-.42 1.761-.63 2.765-.63 1.82 0 3.29.805 4.41 2.415V1.675h4.165V26h-3.85l-.21-2.275Zm-3.465-12.39c-1.19 0-2.089.478-2.695 1.435-.584.933-.875 2.45-.875 4.55 0 2.006.28 3.465.84 4.375.56.91 1.411 1.365 2.555 1.365 1.143 0 2.006-.432 2.59-1.295.583-.887.898-2.31.945-4.27v-.91c-.047-1.82-.35-3.15-.91-3.99-.56-.84-1.377-1.26-2.45-1.26ZM78.517 18.685c.07 1.47.432 2.567 1.085 3.29.653.723 1.75 1.085 3.29 1.085 1.027 0 2.03-.163 3.01-.49 1.004-.327 1.832-.712 2.485-1.155v3.395c-.606.42-1.4.782-2.38 1.085-.98.303-2.135.455-3.465.455-2.683 0-4.748-.747-6.195-2.24-1.423-1.494-2.135-3.78-2.135-6.86 0-1.33.152-2.555.455-3.675.303-1.12.758-2.088 1.365-2.905a6.242 6.242 0 0 1 2.345-1.925c.957-.467 2.065-.7 3.325-.7 1.05 0 1.995.151 2.835.455.84.303 1.552.793 2.135 1.47.607.676 1.074 1.551 1.4 2.625.327 1.05.49 2.345.49 3.885v2.205H78.517Zm3.115-7.35c-1.003 0-1.761.361-2.275 1.085-.513.723-.793 1.785-.84 3.185h5.81v-.35a7.87 7.87 0 0 0-.14-1.54c-.07-.49-.21-.91-.42-1.26s-.49-.619-.84-.805c-.35-.21-.782-.315-1.295-.315Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M97.548 20.085 94.013 26h-4.235l5.53-9.135-5.32-8.47h4.69l3.325 5.32 3.045-5.32h4.165l-4.97 8.54 5.74 9.065h-4.69l-3.745-5.915Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M121.237 1.675c2.894 0 5.122.665 6.685 1.995 1.564 1.33 2.345 3.231 2.345 5.705 0 1.353-.221 2.531-.665 3.535a6.364 6.364 0 0 1-1.82 2.45c-.77.653-1.715 1.143-2.835 1.47-1.12.327-2.368.49-3.745.49h-2.065V26h-4.235V1.675h6.335Zm0 12.355c1.47 0 2.625-.373 3.465-1.12.84-.747 1.26-1.925 1.26-3.535 0-1.54-.396-2.66-1.19-3.36-.77-.7-1.901-1.05-3.395-1.05h-2.24v9.065h2.1Z"
      clipRule="evenodd"
    />
    <path
      fill="#21201F"
      d="M136.875 26h-4.165V8.395h4.06l.105 3.5h.105c.49-1.167 1.202-2.089 2.135-2.765.933-.677 1.995-1.05 3.185-1.12v3.92c-1.283-.07-2.368.163-3.255.7-.863.513-1.587 1.306-2.17 2.38V26Z"
    />
    <path
      fill="#21201F"
      fillRule="evenodd"
      d="M151.64 8.045c1.144 0 2.194.198 3.15.595a6.392 6.392 0 0 1 2.45 1.785c.677.77 1.202 1.726 1.575 2.87.397 1.12.595 2.415.595 3.885 0 1.47-.198 2.776-.595 3.92-.373 1.143-.898 2.111-1.575 2.905a6.557 6.557 0 0 1-2.45 1.75c-.956.396-2.006.595-3.15.595-1.143 0-2.193-.199-3.15-.595a6.573 6.573 0 0 1-2.45-1.75c-.676-.793-1.213-1.75-1.61-2.87-.373-1.144-.56-2.45-.56-3.92 0-1.47.187-2.777.56-3.92.397-1.143.934-2.1 1.61-2.87a6.408 6.408 0 0 1 2.45-1.785c.957-.397 2.007-.595 3.15-.595Zm0 15.015c1.167 0 2.03-.467 2.59-1.4.584-.933.875-2.427.875-4.48 0-2.053-.291-3.535-.875-4.445-.56-.933-1.423-1.4-2.59-1.4-1.166 0-2.041.466-2.625 1.4-.56.933-.84 2.427-.84 4.48 0 2.053.28 3.547.84 4.48.584.91 1.459 1.365 2.625 1.365Z"
      clipRule="evenodd"
    />
  </svg>
);

export const CrossSeparator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9845 0.00195312L20 2.02772L12.0135 10.055L19.8945 17.9764L17.8791 20.0021L9.99806 12.0808L2.12092 19.9982L0.10546 17.9725L7.9826 10.055L0 2.03163L2.01546 0.00585933L9.99806 8.02928L17.9845 0.00195312Z"
      fill="#21201F"
    />
  </svg>
);
