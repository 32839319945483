/* @jsxRuntime automatic */

import { CalendarDate, type DateValue } from "@internationalized/date";

export const getDateKey = (year: number, month: number): string => {
  return `${year}.${month.toString().padStart(2, "0")}`;
};

export const getMonthByNumber = (
  monthId: number,
  locale: string,
  format: "long" | "short" = "long",
) => {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(monthId - 1);

  return objDate.toLocaleString(locale, { month: format });
};

export const getMonthsInYear = (locale: string) => {
  return new Array(12).fill(0).map((_, i) => getMonthByNumber(i + 1, locale));
};

export const dateInRange = ({
  year,
  month,
  minValue,
  maxValue,
}: {
  year: number;
  month: number;
  minValue?: DateValue | null;
  maxValue?: DateValue | null;
}): boolean => {
  const dateToStr = ({ year, month }: { year: number; month: number }) =>
    `${year}-${month.toString().padStart(2, "0")}`;

  const currentDateStr = dateToStr({ year, month });

  return (
    (!minValue || currentDateStr >= dateToStr(minValue)) &&
    (!maxValue || currentDateStr <= dateToStr(maxValue))
  );
};

export const makeDateRangeList = (
  start: { year: number; month: number; day: number },
  end: { year: number; month: number; day: number },
): CalendarDate[] => {
  const result: CalendarDate[] = [];

  const startDate = new Date(start.year, start.month - 1, start.day);
  const endDate = new Date(end.year, end.month - 1, end.day);

  while (startDate <= endDate) {
    const dateValue: DateValue = new CalendarDate(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
    );
    result.push(dateValue);

    startDate.setDate(startDate.getDate() + 1);
  }

  return result;
};

export const makeDateRange = (
  pointA: { year: number; month: number },
  pointB: { year: number; month: number },
): Record<string, boolean> => {
  let { year: yearA, month: monthA } = pointA;
  let { year: yearB, month: monthB } = pointB;

  const result: Record<string, boolean> = {};

  while (yearA !== yearB || monthA !== monthB) {
    const key = getDateKey(yearA, monthA);
    result[key] = true;
    monthA += yearA * 12 + monthA < yearB * 12 + monthB ? 1 : -1;
    if (monthA === 0) {
      monthA = 12;
      yearA--;
    }
    if (monthA === 13) {
      monthA = 1;
      yearA++;
    }
  }

  const key = getDateKey(yearB, monthB);
  result[key] = true;
  return result;
};

export const getYearsRange = (year: number) => {
  let startYear = Math.floor((year - 1) / 12) * 12 + 1;

  const years = [];
  for (let i = 0; i < 12; i++) {
    years.push(startYear + i);
  }

  return years;
};

export const useAnchorMonth = (
  anchor: CalendarDate | null,
  dateKey: string | null,
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};

  if (anchor === null) {
    return {};
  }

  if (dateKey === null) {
    const key = getDateKey(anchor.year, anchor.month);
    result[key] = true;
    return result;
  }

  const [hoverYear, hoverMonth] = dateKey.split(".");

  return makeDateRange(
    { year: anchor.year, month: anchor.month },
    { year: Number(hoverYear), month: Number(hoverMonth) },
  );
};

const getYearRange = (start: number, end: number): number[] => {
  let rangeArray = [];
  let step = start < end ? 1 : -1;

  for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
    rangeArray.push(i);
  }

  return rangeArray;
};

export const useAnchorYear = (
  anchor: CalendarDate | null,
  hoverYear: string | null,
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};

  if (anchor === null) {
    return {};
  }

  if (hoverYear === null) {
    result[anchor.year] = true;
    return result;
  }

  getYearRange(anchor.year, Number(hoverYear)).forEach((year) => {
    result[year] = true;
  });

  return result;
};
