import "@formatjs/intl-locale/polyfill";

declare global {
  namespace Intl {
    interface Locale extends LocaleOptions {
      readonly textInfo: {
        readonly direction: "ltr" | "rtl";
      };
    }

    // https://github.com/microsoft/TypeScript/issues/51023

    interface ResolvedListFormatOptions {
      locale: string;
      style: ListFormatStyle;
      type: ListFormatType;
    }

    interface ListFormat {
      /**
       * Returns a new object with properties reflecting the locale and style
       * formatting options computed during the construction of the current
       * `Intl.ListFormat` object.
       *
       * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/resolvedOptions).
       */
      resolvedOptions(): ResolvedListFormatOptions;
    }
  }
}

if (!("textInfo" in Intl.Locale.prototype)) {
  Object.defineProperty(Intl.Locale.prototype, "textInfo", {
    get() {
      return Object.freeze({
        direction: ["Arab", "Aran", "Adlm", "Hebr"].includes(
          this.maximize().script,
        )
          ? "rtl"
          : "ltr",
      });
    },
  });
}
