/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import {
  YangoLogo,
  YandexLogo,
  YandexIntLogo,
  CrossSeparator,
} from "../images/logo";
import { useBrand } from "../router";

import { LanguageSelector } from "./language-selector";

export const PageHeader = ({
  logoHeight,
  partnerName,
}: {
  logoHeight: number;
  partnerName?: string;
}) => {
  const typo = useTypo();
  const brand = useBrand();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  const iconSize = isMobile ? "10px" : "20px";

  const yangoBrand = brand === "yango";
  const yandexRuLocation = /\.yandex\.ru$/.test(window.location.hostname);

  return (
    <header
      css={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <div
        css={{
          color: uiColors.text,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "end",
        }}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "auto auto 1fr",
            alignItems: "center",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              paddingInlineEnd: isMobile ? "6px" : yangoBrand ? "16px" : "20px",
              justifyContent: "end",
            }}
          >
            {yangoBrand ? (
              <YangoLogo height={logoHeight} width={isMobile ? 45 : 80} />
            ) : yandexRuLocation ? (
              <YandexLogo height={logoHeight} width={isMobile ? 83 : 163} />
            ) : (
              <YandexIntLogo height={logoHeight} width={isMobile ? 83 : 163} />
            )}
          </div>
          <CrossSeparator width={iconSize} height={iconSize} />
          <span
            css={{
              maxWidth: !isMobile ? "242px" : undefined,
              paddingInlineStart: isMobile ? "6px" : "16px",
              ...typo({
                level: isMobile ? "body2" : "title3",
                density: "normal",
                weight: "medium",
              }),
            }}
          >
            {partnerName}
          </span>
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          insetInlineEnd: "0",
          insetBlockStart: "2px",
        }}
      >
        <LanguageSelector view={isMobile ? "shortened" : "default"} />
      </div>
    </header>
  );
};
