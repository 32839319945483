/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ComponentType, type PointerEvent } from "react";
import { mergeProps, useFocusRing, useHover, useLink } from "react-aria";

import { cssFns, useCss } from "@superweb/css";
import { useIsMobile, useTypo, useUiColors, icons } from "@superweb/ui";

export const LinkButton = ({
  ariaLabel,
  icon: Icon,
  onPress,
  text,
  link,
}: {
  onPress?: () => void;
  text?: string;
  icon?: ComponentType<{ className?: string }>;
  ariaLabel?: string;
  link: {
    href: string;
    onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
    external?: boolean;
  };
}) => {
  const css = useCss();
  const ref = useRef(null);
  const internalRef = ref;
  const isMobile = useIsMobile();

  const { linkProps, isPressed: isLinkPressed } = useLink(
    {
      "aria-label": ariaLabel,
      onPress,
    },
    internalRef,
  );

  const { hoverProps, isHovered } = useHover({});
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  const typo = useTypo();
  const uiColors = useUiColors();

  const isPressed = isLinkPressed;
  const transparent = cssFns.transparentize(uiColors.everBack, 1);

  const baseColor = transparent;

  const textColor = uiColors.textOnControlMain;

  const backgroundColor =
    isPressed || isHovered
      ? cssFns.blend(baseColor, isPressed ? uiColors.press : uiColors.hover)
      : baseColor;

  const iconSize = isMobile ? "16px" : "24px";

  const externalLinkIconSize = isMobile ? "14px" : "16px";

  const Content = (
    <div
      css={{
        display: "flex",
        alignContent: "center",
        justifyContent: "start",
        columnGap: isMobile ? "4px" : "6px",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        isolation: "isolate",
        ...cssFns.overflow("hidden"),
        ...cssFns.border({
          radius: "16px",
        }),
        boxShadow: `0 0 0 0 transparent, ${
          isFocused
            ? "inset 0 0 0 2px " + uiColors.focus
            : "0 0 0 0 transparent"
        }`,
        cursor: "pointer",
        height: "30px",
        backgroundColor,
        color: textColor,
        outlineStyle: "none",
        ...cssFns.padding("4px", "8px"),
      }}
    >
      {Icon && (
        <Icon
          className={css({
            gridColumnStart: "icon",
            gridRowStart: "content",
            width: iconSize,
            height: iconSize,
            display: "flex",
            alignItems: "stretch",
            justifyItems: "stretch",
          })}
        />
      )}
      {text && (
        <span
          css={{
            ...typo({
              level: isMobile ? "caption1" : "body2",
              weight: "regular",
              density: "tight",
            }),
            ...cssFns.overflow("hidden"),
            gridColumnStart: "text",
            gridRowStart: "content",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </span>
      )}
      {link.external && (
        <icons.NewTab
          width={externalLinkIconSize}
          height={externalLinkIconSize}
        />
      )}
    </div>
  );

  return (
    <a
      {...mergeProps(linkProps, hoverProps, focusProps)}
      href={link.href}
      ref={internalRef}
      onClick={link.onClick}
      {...(link.external && { target: "_blank", rel: "noreferrer" })}
      css={{
        display: "inline-block",
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        ...cssFns.border({ style: "none" }),
        outlineStyle: "none",
        backgroundColor: "transparent",
        textAlign: "center",
        textDecorationLine: "none",
      }}
    >
      {Content}
    </a>
  );
};
