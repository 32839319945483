/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useMemo } from "react";

import {
  ComboBox,
  useDebouncedState,
  type ComboBoxOption,
  type ComboBoxState,
} from "@superweb/ui";

export const FormSuggest = ({
  state,
  data,
  label,
  description,
  onChange,
  disabled,
  required,
  enableFilterOptionsOnInputValue = true,
}: {
  state: ComboBoxState<ComboBoxOption>;
  data?: {
    label: string;
    key: string;
  }[];
  label: string;
  description?: string;
  onChange: (state: ComboBoxState<ComboBoxOption>) => void;
  disabled?: boolean;
  required?: boolean;
  enableFilterOptionsOnInputValue?: boolean;
}) => {
  const debouncedInput = useDebouncedState(state.inputValue, 300);

  const enabled = Boolean(debouncedInput || state.value);

  const selectedItem = useMemo(() => {
    const items = enabled && data;
    if (!items) return;

    const selected = items.find(({ key }) => key === state.value?.key);

    return selected && selected.label;
  }, [data, enabled, state.value]);

  const options = useMemo(
    () =>
      data?.length
        ? enableFilterOptionsOnInputValue
          ? data.filter(({ label }) =>
              label.toLowerCase().includes(debouncedInput.toLowerCase()),
            )
          : data
        : [],
    [data, debouncedInput, enableFilterOptionsOnInputValue],
  );

  return (
    <ComboBox
      required={required}
      label={label}
      description={description}
      disabled={disabled}
      state={
        !state.inputValue && selectedItem
          ? { ...state, inputValue: selectedItem }
          : state
      }
      options={options}
      onChange={(state) => {
        onChange(state);
      }}
    />
  );
};
