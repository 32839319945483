import { useState, useEffect, useRef } from "react";

/**
 * Delays update until after `delay` milliseconds have elapsed
 * since the last time `state` was changed.
 *
 * Relies on `useEffect` to detect `state` change.
 *
 * Resets the timer on `delay` change.
 */
export const useDebouncedState = <T>(state: T, delay: number): T => {
  const [debouncedState, setDebouncedState] = useState(state);
  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedState(state);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [state, delay]);
  return debouncedState;
};

/**
 * @deprecated
 * 🚩 This hook encourages a flaved coding practice! 🚩
 *
 * Try to avoid it.
 *
 * Consider React docs for alternatives:
 * - [You Might Not Need an Effect](https://react.dev/learn/you-might-not-need-an-effect)
 * - [Separating Events from Effects](https://react.dev/learn/separating-events-from-effects)
 * - [Removing Effect Dependencies](https://react.dev/learn/removing-effect-dependencies#to-remove-a-dependency-prove-that-its-not-a-dependency)
 *
 * ---
 *
 * Returns a value of `state` which was observed
 * during previous render.
 *
 * Contrary to its name, it does not keep previous state completely.
 * Returned value will re-sync with `state` on next-after-update render.
 *
 * Given:
 * ```ts
 * const prev = usePreviousState(state);
 * ```
 *
 * - During first render, `prev` will be `undefined`.
 * - If `state` changes, during next render `prev` will be `state`'s previous value.
 * - During a render after that, `prev` will be equal to `state`.
 *
 * It's useful to react to a state change just once:
 *
 * ```ts
 * const [number, setNumber] = useState(0);
 * const prevNumber = usePreviousState(number);
 *
 * useEffect(() => {
 *   if (number !== prevNumber) {
 *     console.log("number changed");
 *   }
 * }, [number, prevNumber]);
 * ```
 *
 * Here, `"number changed"` will be logged only once per `number` change.
 */
export const usePreviousState = <T>(state: T): T | undefined => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
};
