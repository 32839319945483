import type React from "react";
import * as r from "react/jsx-runtime";
import { wrap } from "./wrap";
import type { CssProps } from "../css";

export const jsx = wrap(r.jsx);
export const jsxs = wrap(r.jsxs);
export const Fragment = r.Fragment;

export namespace JSX {
  export type ElementType = string | React.JSXElementConstructor<any>;

  export interface Element extends globalThis.JSX.Element {}

  export interface ElementClass extends globalThis.JSX.ElementClass {}

  export interface ElementAttributesProperty
    extends globalThis.JSX.ElementAttributesProperty {}

  export interface ElementChildrenAttribute
    extends globalThis.JSX.ElementChildrenAttribute {}

  export type LibraryManagedAttributes<C, P> =
    globalThis.JSX.LibraryManagedAttributes<C, P>;

  export interface IntrinsicAttributes
    extends globalThis.JSX.IntrinsicAttributes {}

  export interface IntrinsicClassAttributes<T>
    extends globalThis.JSX.IntrinsicClassAttributes<T> {}

  export type IntrinsicElements = {
    [K in keyof globalThis.JSX.IntrinsicElements]: Omit<
      globalThis.JSX.IntrinsicElements[K],
      "className"
    > &
      CssProps;
  };
}
