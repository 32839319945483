import type { SVGProps } from "react";

import { useLocale } from "@superweb/intl";
import { icons } from "@superweb/ui";

export const ArrowInlineEnd = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ArrowLeft {...props} />
  ) : (
    <icons.ArrowRight {...props} />
  );
};

export const ArrowInlineStart = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ArrowRight {...props} />
  ) : (
    <icons.ArrowLeft {...props} />
  );
};

export const ChevronInlineEnd = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronLeft {...props} />
  ) : (
    <icons.ChevronRight {...props} />
  );
};

export const ChevronInlineEndFillM = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronLeftFillM {...props} />
  ) : (
    <icons.ChevronRightFillM {...props} />
  );
};

export const ChevronInlineEndL = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronLeftL {...props} />
  ) : (
    <icons.ChevronRightL {...props} />
  );
};

export const ChevronInlineEndFillL = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronLeftFillL {...props} />
  ) : (
    <icons.ChevronRightFillL {...props} />
  );
};

export const ChevronInlineStart = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronRight {...props} />
  ) : (
    <icons.ChevronLeft {...props} />
  );
};

export const ChevronInlineStartFillM = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronRightFillM {...props} />
  ) : (
    <icons.ChevronLeftFillM {...props} />
  );
};

export const ChevronInlineStartL = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronRightL {...props} />
  ) : (
    <icons.ChevronLeftL {...props} />
  );
};

export const ChevronInlineStartFillL = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.ChevronRightFillL {...props} />
  ) : (
    <icons.ChevronLeftFillL {...props} />
  );
};

export const CornerInlineEnd = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.CornerLeft {...props} />
  ) : (
    <icons.CornerRight {...props} />
  );
};

export const CornerInlineStart = (props: SVGProps<SVGSVGElement>) => {
  const { textInfo } = useLocale();
  return textInfo.direction === "rtl" ? (
    <icons.CornerRight {...props} />
  ) : (
    <icons.CornerLeft {...props} />
  );
};

export const SumOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M8.82617 17.3091C9.03353 17.436 9.28744 17.4995 9.58789 17.4995H14.7612C15.0828 17.4995 15.3346 17.4106 15.5166 17.2329C15.7028 17.0552 15.7959 16.8245 15.7959 16.541C15.7959 16.2533 15.7028 16.0226 15.5166 15.8491C15.3346 15.6756 15.0828 15.5889 14.7612 15.5889H10.8257V15.5127L13.4092 13.0117C13.5869 12.8636 13.7202 12.7007 13.8091 12.5229C13.9022 12.341 13.9487 12.1527 13.9487 11.958C13.9487 11.7676 13.9022 11.5814 13.8091 11.3994C13.7202 11.2174 13.5869 11.0524 13.4092 10.9043L10.832 8.42236V8.34619H14.7612C15.0871 8.34619 15.341 8.25944 15.5229 8.08594C15.7049 7.9082 15.7959 7.67757 15.7959 7.39404C15.7959 7.10628 15.7049 6.87565 15.5229 6.70215C15.341 6.52441 15.0871 6.43555 14.7612 6.43555H9.58789C9.29167 6.43555 9.03988 6.49902 8.83252 6.62598C8.62516 6.75293 8.46647 6.91797 8.35645 7.12109C8.25065 7.32422 8.19775 7.54215 8.19775 7.7749C8.19775 7.99072 8.24854 8.20866 8.3501 8.42871C8.45166 8.64876 8.60612 8.84342 8.81348 9.0127L11.8667 11.9009V11.9897L8.81348 14.9224C8.60189 15.0916 8.44531 15.2863 8.34375 15.5063C8.24642 15.7264 8.19775 15.9443 8.19775 16.1602C8.19775 16.3929 8.25065 16.6108 8.35645 16.814C8.46647 17.0171 8.62305 17.1821 8.82617 17.3091Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315Z"
      fill="currentColor"
    />
  </svg>
);

export const FolderWithFile = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" {...props}>
    <path d="M4.442 11.172h4.165v1.334H4.442v-1.334Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 1.834c-.736 0-1.333.597-1.333 1.333v2.396c-.736 0-1.333.597-1.333 1.334v6.936c0 .737.597 1.334 1.333 1.334h10.667c.736 0 1.333-.597 1.333-1.334v-5c0-.736-.597-1.333-1.333-1.333V3.167c0-.736-.597-1.333-1.333-1.333h-8Zm8 5.666H8.807L7.54 6.027a1.333 1.333 0 0 0-1.01-.464H4.5V3.167h8V7.5Zm-5.972-.603h-3.36v6.936h10.666v-5H8.807c-.389 0-.758-.169-1.011-.463L6.528 6.897Z"
      fill="currentColor"
    />
  </svg>
);

export const Money = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" {...props}>
    <path
      d="m13.059 3.503.407 2.447h1.352l-.546-3.28a.803.803 0 0 0-1.09-.613c-.548.218-1.271.366-2.14.48-.728.095-1.522.162-2.361.233l-.473.04c-1.99.17-4.204.39-5.98 1.113a.798.798 0 0 0-.479.912l.246 1.115h1.366L3.15 5c1.491-.513 3.325-.704 5.17-.862l.464-.039a54.53 54.53 0 0 0 2.43-.24c.651-.085 1.28-.195 1.844-.356ZM5.84 12.833V9.167h1.334v3.666H5.84ZM10.84 13.116h2.334v-1.333H10.84v1.333ZM5.29 9.95a.783.783 0 1 1-1.567 0 .783.783 0 0 1 1.567 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.84 7.967a.8.8 0 0 1 .8-.8h11.837a.8.8 0 0 1 .8.8v6.4a.8.8 0 0 1-.8.8H2.64a.8.8 0 0 1-.8-.8v-6.4Zm1.334.533v5.333h10.77V8.5H3.174Z"
      fill="currentColor"
    />
  </svg>
);

export const ParametersOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4c.439 0 .85 0 1.162.021.33.023.66.072.986.207a3 3 0 0 1 1.624 1.624c.135.326.184.656.207.986l.009.162H22v2h-2.012l-.01.162c-.022.33-.071.66-.206.986a3 3 0 0 1-1.624 1.624 3.073 3.073 0 0 1-.986.207C16.85 12 16.44 12 16 12c-.439 0-.85 0-1.162-.021a3.072 3.072 0 0 1-.986-.207 3 3 0 0 1-1.624-1.624 3.07 3.07 0 0 1-.207-.986A6.416 6.416 0 0 1 12.012 9H2V7h10.012l.01-.162c.022-.33.071-.66.206-.986a3 3 0 0 1 1.624-1.624c.326-.135.656-.184.986-.207C15.15 4 15.56 4 16 4Zm-1.026 2.017c-.228.015-.315.042-.357.06a1 1 0 0 0-.54.54c-.018.042-.045.129-.06.357C14 7.21 14 7.52 14 8s0 .79.017 1.026c.015.228.042.315.06.357a1 1 0 0 0 .54.54c.042.018.129.045.357.06C15.21 10 15.52 10 16 10s.79 0 1.026-.017c.228-.015.315-.042.357-.06a1 1 0 0 0 .54-.54c.018-.042.045-.129.06-.357C18 8.79 18 8.48 18 8s0-.79-.017-1.026c-.015-.228-.042-.315-.06-.357a1 1 0 0 0-.54-.54c-.042-.018-.129-.045-.357-.06A16.908 16.908 0 0 0 16 6c-.48 0-.79 0-1.026.017ZM4.012 15H2v2h2.012l.01.162c.022.33.071.66.206.986a3 3 0 0 0 1.624 1.624c.326.135.656.184.986.207C7.15 20 7.56 20 8 20c.438 0 .85 0 1.162-.021.33-.023.66-.072.986-.207a3 3 0 0 0 1.624-1.624c.135-.326.184-.656.207-.986l.009-.162H22v-2H11.988a6.928 6.928 0 0 0-.01-.162 3.072 3.072 0 0 0-.206-.986 3 3 0 0 0-1.624-1.624 3.07 3.07 0 0 0-.986-.207C8.85 12 8.44 12 8 12c-.439 0-.85 0-1.162.021a3.07 3.07 0 0 0-.986.207 3 3 0 0 0-1.624 1.624 3.069 3.069 0 0 0-.207.986 6.566 6.566 0 0 0-.009.162Zm2.605-.924c.042-.017.129-.044.357-.06C7.21 14.002 7.52 14 8 14s.79 0 1.026.017c.228.015.315.042.357.06a1 1 0 0 1 .54.54c.018.042.045.129.06.357C10 15.21 10 15.52 10 16s0 .79-.017 1.026c-.015.228-.042.315-.06.357a1 1 0 0 1-.54.54c-.042.018-.129.045-.357.06C8.79 18 8.48 18 8 18s-.79 0-1.026-.017c-.228-.015-.315-.042-.357-.06a1 1 0 0 1-.54-.54c-.018-.042-.045-.129-.06-.357A16.908 16.908 0 0 1 6 16c0-.48 0-.79.017-1.026.015-.228.042-.315.06-.357a1 1 0 0 1 .54-.54Z"
      fill="currentColor"
    />
  </svg>
);

export const Passport = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.166 6.443c0-.737.597-1.334 1.333-1.334h2.619c.736 0 1.333.597 1.333 1.334v4.065c0 .736-.597 1.333-1.333 1.333H4.499a1.333 1.333 0 0 1-1.333-1.333V6.443Zm3.952 0H4.499v4.065h2.619V6.443Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M9.533 6.531h3.934V5.198H9.533V6.53ZM13.467 8.523H9.533V7.19h3.934v1.333ZM9.533 10.658h2.574V9.324H9.533v1.334Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.166 4.547a2 2 0 0 1 2-2h10.667a2 2 0 0 1 2 2v7.858a2 2 0 0 1-2 2H3.166a2 2 0 0 1-2-2V4.547Zm2-.667a.667.667 0 0 0-.667.667v7.858c0 .368.299.666.667.666h10.667a.667.667 0 0 0 .666-.666V4.547a.667.667 0 0 0-.666-.667H3.166Z"
      clipRule="evenodd"
    />
  </svg>
);

export const Block = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C13.7859 4 15.5102 4.59715 16.906 5.68088L5.68088 16.906C4.59715 15.5102 4 13.7859 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315ZM7.09523 18.3201C8.49082 19.4032 10.2146 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 10.2146 19.4032 8.49082 18.3201 7.09523L7.09523 18.3201Z"
    />
  </svg>
);

export const RecurringPaymentsOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.502 11.598V6.5h3.142c.575 0 1.098.068 1.568.204.481.125.89.324 1.224.596.335.272.596.617.784 1.035.188.419.283.915.283 1.49 0 .586-.1 1.083-.299 1.49a2.636 2.636 0 0 1-.8.989c-.345.261-.758.45-1.239.565a6.812 6.812 0 0 1-1.584.172h-.836v.941h3.06v1.444h-3.06V17.5H9.502v-2.075H8v-1.443h1.502v-.94H8v-1.444h1.502Zm2.243-3.357v3.357h.867c1.109 0 1.663-.586 1.663-1.757 0-.585-.13-.999-.392-1.24-.251-.24-.664-.36-1.24-.36h-.898Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.883 3.606c.54-.337 1.19-.486 2.117-.553V2h2v1h6V2h2v1.053c.927.067 1.577.216 2.117.553a4 4 0 0 1 1.277 1.277C21 5.855 21 7.183 21 9.84v4.32c0 2.657 0 3.985-.606 4.957a4 4 0 0 1-1.277 1.277c-.972.606-2.3.606-4.957.606H9.84c-2.657 0-3.985 0-4.957-.606a4 4 0 0 1-1.277-1.277C3 18.145 3 16.817 3 14.16V9.84c0-2.657 0-3.985.606-4.957a4 4 0 0 1 1.277-1.277ZM9.84 5 9 5.001V6H7v-.94a8.614 8.614 0 0 0-.078.007c-.637.06-.865.164-.98.236a2 2 0 0 0-.639.639c-.072.115-.175.343-.236.98-.05.52-.063 1.17-.066 2.078L5 14.16c0 1.368.002 2.251.067 2.918.06.637.164.865.236.98a2 2 0 0 0 .639.639c.115.072.343.175.98.236.667.065 1.55.067 2.918.067h4.32c1.368 0 2.251-.002 2.918-.067.637-.06.865-.164.98-.236a2 2 0 0 0 .639-.639c.072-.115.175-.343.236-.98.065-.667.067-1.55.067-2.918L18.999 9c-.003-.908-.015-1.559-.066-2.078-.06-.637-.164-.865-.236-.98a2 2 0 0 0-.639-.639c-.115-.072-.343-.175-.98-.236A8.475 8.475 0 0 0 17 5.059V6h-2v-.999L14.16 5H9.84Z"
      fill="currentColor"
    />
  </svg>
);

export const CalendarCheckOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="m11 16 6.5-6.5L16 8l-4.99 5L8 10l-1.5 1.5L11 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.883 3.606c.54-.337 1.19-.486 2.117-.553V2h2v1h6V2h2v1.053c.927.067 1.577.216 2.117.553a4 4 0 0 1 1.277 1.277C21 5.855 21 7.183 21 9.84v4.32c0 2.657 0 3.985-.606 4.957a4 4 0 0 1-1.277 1.277c-.972.606-2.3.606-4.957.606H9.84c-2.657 0-3.985 0-4.957-.606a4 4 0 0 1-1.277-1.277C3 18.145 3 16.817 3 14.16V9.84c0-2.657 0-3.985.606-4.957a4 4 0 0 1 1.277-1.277ZM9.84 5 9 5.001V6H7v-.94a8.614 8.614 0 0 0-.078.007c-.637.06-.865.164-.98.236a2 2 0 0 0-.639.639c-.072.115-.175.343-.236.98-.05.52-.063 1.17-.066 2.078L5 14.16c0 1.368.002 2.251.067 2.918.06.637.164.865.236.98a2 2 0 0 0 .639.639c.115.072.343.175.98.236.667.065 1.55.067 2.918.067h4.32c1.368 0 2.251-.002 2.918-.067.637-.06.865-.164.98-.236a2 2 0 0 0 .639-.639c.072-.115.175-.343.236-.98.065-.667.067-1.55.067-2.918L18.999 9c-.003-.908-.015-1.559-.066-2.078-.06-.637-.164-.865-.236-.98a2 2 0 0 0-.639-.639c-.115-.072-.343-.175-.98-.236A8.475 8.475 0 0 0 17 5.059V6h-2v-.999L14.16 5H9.84Z"
      fill="currentColor"
    />
  </svg>
);

export const CalendarPauseOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M14 16V8h2v8h-2ZM8 16V8h2v8H8Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.053c-.927.067-1.577.216-2.117.553a4 4 0 0 0-1.277 1.277C3 5.855 3 7.183 3 9.84v4.32c0 2.657 0 3.985.606 4.957a4 4 0 0 0 1.277 1.277C5.855 21 7.183 21 9.84 21h4.32c2.657 0 3.985 0 4.957-.606a4 4 0 0 0 1.277-1.277c.606-.972.606-2.3.606-4.957V9.84c0-2.657 0-3.985-.606-4.957a4 4 0 0 0-1.277-1.277c-.54-.337-1.19-.486-2.117-.553V2h-2v1H9V2H7v1.053Zm2 1.948L9.84 5h4.32l.84.001V6h2v-.94l.078.007c.637.06.865.164.98.236a2 2 0 0 1 .639.639c.072.115.175.343.236.98.05.52.063 1.17.066 2.078L19 14.16c0 1.368-.002 2.251-.067 2.918-.06.637-.164.865-.236.98a2 2 0 0 1-.639.639c-.115.072-.343.175-.98.236-.667.065-1.55.067-2.918.067H9.84c-1.368 0-2.251-.002-2.918-.067-.637-.06-.865-.164-.98-.236a2 2 0 0 1-.639-.639c-.072-.115-.175-.343-.236-.98C5.002 16.411 5 15.528 5 14.16L5.001 9c.003-.908.015-1.559.066-2.078.06-.637.164-.865.236-.98a2 2 0 0 1 .639-.639c.115-.072.343-.175.98-.236L7 5.059V6h2v-.999Z"
      fill="currentColor"
    />
  </svg>
);

export const CalendarCancelOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16 9.406 13.406 12l2.59 2.59-1.406 1.407-2.59-2.59L9.406 16 8 14.594 10.594 12l-2.59-2.59L9.41 8.003l2.59 2.59L14.594 8 16 9.406Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.883 3.606c.54-.337 1.19-.486 2.117-.553V2h2v1h6V2h2v1.053c.927.067 1.577.216 2.117.553a4 4 0 0 1 1.277 1.277C21 5.855 21 7.183 21 9.84v4.32c0 2.657 0 3.985-.606 4.957a4 4 0 0 1-1.277 1.277c-.972.606-2.3.606-4.957.606H9.84c-2.657 0-3.985 0-4.957-.606a4 4 0 0 1-1.277-1.277C3 18.145 3 16.817 3 14.16V9.84c0-2.657 0-3.985.606-4.957a4 4 0 0 1 1.277-1.277ZM9.84 5 9 5.001V6H7v-.94a8.614 8.614 0 0 0-.078.007c-.637.06-.865.164-.98.236a2 2 0 0 0-.639.639c-.072.115-.175.343-.236.98-.05.52-.063 1.17-.066 2.078L5 14.16c0 1.368.002 2.251.067 2.918.06.637.164.865.236.98a2 2 0 0 0 .639.639c.115.072.343.175.98.236.667.065 1.55.067 2.918.067h4.32c1.368 0 2.251-.002 2.918-.067.637-.06.865-.164.98-.236a2 2 0 0 0 .639-.639c.072-.115.175-.343.236-.98.065-.667.067-1.55.067-2.918L18.999 9c-.003-.908-.015-1.559-.066-2.078-.06-.637-.164-.865-.236-.98a2 2 0 0 0-.639-.639c-.115-.072-.343-.175-.98-.236A8.475 8.475 0 0 0 17 5.059V6h-2v-.999L14.16 5H9.84Z"
      fill="currentColor"
    />
  </svg>
);
