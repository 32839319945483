/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ReactNode } from "react";
import useResizeObserver from "use-resize-observer";

import { Button, useIsMobile, useTypo } from "@superweb/ui";
import { cssFns } from "@superweb/css";

export const SubmitBottomSheet = ({
  isLoading,
  text,
  children,
}: {
  isLoading: boolean;
  text: string;
  children?: ReactNode;
}) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const typo = useTypo();
  const isMobile = useIsMobile();

  const { height: bottomSheetHeight = 0 } = useResizeObserver({
    ref: bottomSheetRef,
  });

  return (
    <>
      <div
        css={{
          height: `${bottomSheetHeight}px`,
        }}
      />
      <div
        ref={bottomSheetRef}
        css={{
          position: "absolute",
          bottom: "18px",
          insetInlineStart: "8px",
          width: "calc(100% - 16px)",
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        <Button
          view="action"
          type="submit"
          shape="squircle"
          progress={isLoading}
          text={text}
        />
        {children ? (
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...(isMobile
                ? cssFns.padding("8px", "0")
                : cssFns.padding("4px", "16px")),
              marginBlockEnd: "-18px",
              minHeight: "48px",
              textAlign: "center",
            }}
          >
            <div
              css={{
                ...typo({
                  level: "caption1",
                  weight: "regular",
                  density: "tight",
                }),
              }}
            >
              {children}
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
};
