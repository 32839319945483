/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useCallback,
  useMemo,
  useState,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";

type ActionUrl = {
  action_type: "url";
  url: string;
  is_url_external: boolean;
};

type ActionNext = {
  action_type: "next";
};

type ScenarioDialogParameters = {
  id: "scenario";
  scenario_id: string;
};

type ActionDialog = {
  action_type: "dialog";
  parameters: ScenarioDialogParameters;
};

export type ButtonAction = ActionNext | ActionUrl | ActionDialog;

type ActionButtons = {
  primaryButtonAction?: ButtonAction;
  secondaryButtonAction?: ButtonAction;
};

type View = "outline" | "action" | "contrast" | "contrastOutline";

export type SlideProps = {
  textPosition?: "top" | "center" | "bottom";
  titleText?: string;
  titleColor?: string;
  text?: string;
  textColor?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundMode?: "full" | "pattern";
  image?: string;
  primaryButtonView?: View;
  secondaryButtonView?: View;
  primaryButtonText?: string;
  secondaryButtonText?: string;
};

export type Slides = Array<
  SlideProps &
    ActionButtons &
    Partial<{
      duration: number;
      progressColor: string;
      progressFillerColor: string;
    }>
>;

interface SlidesContextValue {
  brand?: string;
  currentSlideIndex: number;
  nextSlide: (trigger: "timer" | "click" | "keyboard") => void;
  previousSlide: (trigger: "click" | "keyboard") => void;
  holdSlide: (isHolding: boolean) => void;
  isPaused: boolean;
  slides: Slides;
  onActionSlide?: (action: "primary" | "secondary") => void;
  onContinueScenario?: (scenarioId: string) => void;
  onClose: (isTrigger?: boolean) => void;
}

const SlidesContext = createContext<SlidesContextValue>({
  currentSlideIndex: 0,
  nextSlide: () => {},
  previousSlide: () => {},
  isPaused: false,
  holdSlide: () => {},
  slides: [],
  onClose: () => {},
});

export const useSlides = () => useContext(SlidesContext);

export const SlidesProvider = ({
  brand,
  slides,
  currentStoryId,
  currentStoryIndex,
  children,
  onSlideNext,
  onSlidePrevious,
  onActionSlide,
  onSlideStart,
  onContinueScenario,
  onClose,
}: {
  brand?: string;
  slides: Slides;
  currentStoryId: string;
  currentStoryIndex: number;
  children?: ReactNode;
  onSlideNext?: (
    storyId: string,
    indexSlide: number,
    trigger: "timer" | "click" | "keyboard",
  ) => void;
  onSlidePrevious?: (
    storyId: string,
    indexSlide: number,
    trigger: "click" | "keyboard",
  ) => void;
  onActionSlide?: ({
    isExternal,
    url,
    idStory,
    indexSlide,
    typeAction,
  }: {
    isExternal?: boolean;
    url?: string;
    idStory: string;
    indexSlide: number;
    typeAction: "primary" | "secondary";
  }) => void;
  onSlideStart?: (currentIndex: number, currentStoryIndex: number) => void;
  onContinueScenario?: (
    scenarioId: string,
    subStepId?: string,
    options?: {
      startFromActionId?: string;
      allowDialogOpening?: boolean;
    },
  ) => void;
  onClose: (isTrigger?: boolean) => void;
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(!slides[0]?.duration);

  const slide = useMemo(
    () => slides[currentSlideIndex],
    [slides, currentSlideIndex],
  );

  const nextSlide = useCallback(
    (trigger: "timer" | "click" | "keyboard") => {
      const nextIndex =
        currentSlideIndex === slides.length - 1 ? 0 : currentSlideIndex + 1;
      setCurrentSlideIndex(nextIndex);
      onSlideNext?.(currentStoryId, currentSlideIndex, trigger);
      if (nextIndex !== 0) {
        onSlideStart?.(nextIndex, currentStoryIndex);
      }
    },
    [
      currentSlideIndex,
      onSlideNext,
      currentStoryId,
      slides,
      onSlideStart,
      currentStoryIndex,
    ],
  );

  const previousSlide = (trigger: "click" | "keyboard") => {
    const previousIndex = Math.max(currentSlideIndex - 1, 0);
    setCurrentSlideIndex((currentIndex) => {
      if (currentIndex !== previousIndex) {
        onSlideStart?.(previousIndex, currentStoryIndex);
      }
      return previousIndex;
    });
    onSlidePrevious?.(currentStoryId, currentSlideIndex, trigger);
  };

  const holdSlide = (isHolding: boolean) => {
    if (!slide?.duration) return;
    setIsPaused(isHolding);
  };

  const handleActionSlide = (action: "primary" | "secondary") => {
    const buttonAction = {
      primary: slide?.primaryButtonAction,
      secondary: slide?.secondaryButtonAction,
    }[action];
    const metricsData = {
      idStory: currentStoryId,
      indexSlide: currentSlideIndex,
      typeAction: action,
    };

    if (buttonAction?.action_type === "url") {
      if (!buttonAction.is_url_external && buttonAction.url) {
        onClose();
      }
      onActionSlide?.({
        isExternal: buttonAction.is_url_external,
        url: buttonAction.url,
        ...metricsData,
      });
    } else if (buttonAction?.action_type === "dialog") {
      onContinueScenario?.(buttonAction.parameters.scenario_id, undefined, {
        allowDialogOpening: true,
      });
      onClose();
      onActionSlide?.(metricsData);
    } else {
      nextSlide("click");
      onActionSlide?.(metricsData);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!slide?.duration) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  }, [slide]);

  useEffect(() => {
    onSlideStart?.(0, currentStoryIndex);
    setCurrentSlideIndex(0);
  }, [onSlideStart, currentStoryId, currentStoryIndex]);

  return (
    <SlidesContext.Provider
      value={{
        brand,
        nextSlide,
        previousSlide,
        currentSlideIndex,
        isPaused,
        holdSlide,
        slides,
        onActionSlide: handleActionSlide,
        onClose,
      }}
    >
      {children}
    </SlidesContext.Provider>
  );
};
