/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createCalendar,
  getWeeksInMonth,
  toCalendarDate,
  CalendarDate,
  today,
  type DateValue,
} from "@internationalized/date";
import { Temporal } from "@js-temporal/polyfill";
import {
  useRef,
  useState,
  useMemo,
  type ReactNode,
  type RefObject,
  type PointerEvent,
  type KeyboardEvent,
} from "react";
import {
  I18nProvider,
  Overlay,
  mergeProps,
  useButton,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDialog,
  useFocusRing,
  usePopover,
  useRangeCalendar,
  type AriaButtonProps,
  type AriaDialogProps,
  type CalendarAria,
  type CalendarProps,
  type RangeCalendarProps,
} from "react-aria";
import {
  useCalendarState,
  useRangeCalendarState,
  type ListState,
  type CalendarState,
  type CalendarStateOptions,
  type OverlayTriggerState,
  type RangeCalendarState,
  type RangeCalendarStateOptions,
} from "react-stately";
import { useFormat, useLocale, useTz } from "@superweb/intl";
import { useMessage } from "#intl";
import { cssFns } from "@superweb/css";

import { CalendarGrid, type CalendarGridItem } from "./calendar-grid";
import { getMonthByNumber } from "./date-hooks";

import { useUiColors, useUiShadows } from "../theme";
import { FieldButton } from "../fields/buttons";
import { icons } from "../icons";
import { useIsMobile } from "../mobile-context";
import { useTypo } from "../typo";
import { Tooltip, useTooltip } from "../tooltip";

import {
  getYearsRange,
  getMonthsInYear,
  dateInRange,
  getDateKey,
  useAnchorMonth,
  useAnchorYear,
} from "./date-hooks";

type CalendarRange = {
  minLength?: CalendarDate;
  maxLength?: CalendarDate;
};

type CommonCalendarState = CalendarState | RangeCalendarState;
type Variant = "days" | "month" | "year";

export type Preset<V> = {
  label: string;
  value: V;
  /**
   * Recommended to use for analytics.
   */
  onPress?: () => void;
};

export const Calendar = ({
  size = "m",
  presets,
  exclude,
  tooltip,
  ...props
}: CalendarProps<DateValue> & {
  size?: "s" | "m";
  exclude?: (date: DateValue) => boolean;
  tooltip?: (state: { value: Temporal.PlainDate }) => string | undefined;
  presets?: Preset<DateValue>[];
}) => {
  const locale = useLocale();
  const ref = useRef<HTMLDivElement>(null);

  const options: CalendarStateOptions = {
    ...props,
    locale: locale.toString(),
    createCalendar,
    isDateUnavailable: exclude,
  };

  const state = useCalendarState(options);
  const calendarProps = useCalendar(options, state);

  return (
    <BaseCalendar
      ariaProps={calendarProps}
      state={state}
      calendarRef={ref}
      size={size}
      tooltip={tooltip}
      presets={
        !!presets?.length && (
          <PresetContainer size={size}>
            {presets.map((preset, index) => (
              <PresetButton
                key={index}
                text={preset.label}
                onPress={() => {
                  state.setValue(toCalendarDate(preset.value));
                  preset.onPress?.();
                }}
              />
            ))}
          </PresetContainer>
        )
      }
    />
  );
};

export const RangeCalendar = ({
  size = "m",
  presets,
  exclude,
  tooltip,
  minLength,
  maxLength,
  ...props
}: RangeCalendarProps<DateValue> & {
  size?: "s" | "m";
  presets?: Preset<{ start: DateValue; end: DateValue }>[];
  exclude?: (date: DateValue) => boolean;
  tooltip?: (state: { value: Temporal.PlainDate }) => string | undefined;
  minLength?: number;
  maxLength?: number;
}) => {
  const locale = useLocale();
  const ref = useRef<HTMLDivElement>(null);

  const ariaProps: RangeCalendarStateOptions = {
    ...props,
    locale: locale.toString(),
    createCalendar,
    isDateUnavailable: exclude,
  };

  const state = useRangeCalendarState(ariaProps);
  const calendarProps = useRangeCalendar(ariaProps, state, ref);

  return (
    <BaseCalendar
      size={size}
      ariaProps={calendarProps}
      state={state}
      calendarRef={ref}
      tooltip={tooltip}
      minLength={minLength}
      maxLength={maxLength}
      presets={
        !!presets?.length && (
          <PresetContainer size={size}>
            {presets.map((preset, index) => (
              <PresetButton
                key={index}
                text={preset.label}
                onPress={() => {
                  state.setValue(preset.value);
                  preset.onPress?.();
                }}
              />
            ))}
          </PresetContainer>
        )
      }
    />
  );
};

const BaseCalendar = ({
  size = "m",
  ariaProps,
  state,
  calendarRef,
  presets,
  tooltip,
  minLength,
  maxLength,
}: {
  size?: "s" | "m";
  ariaProps: CalendarAria;
  state: CommonCalendarState;
  calendarRef: RefObject<HTMLDivElement>;
  presets?: ReactNode;
  tooltip?: (state: { value: Temporal.PlainDate }) => string | undefined;
  minLength?: number;
  maxLength?: number;
}) => {
  const [originalFocusedDate] = useState(state.focusedDate);
  const [variant, setVariant] = useState<Variant>("days");

  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const locale = useLocale();

  const changeVariant = () => {
    if (variant === "days") {
      return setVariant("month");
    }

    if (variant === "month") {
      return setVariant("year");
    }

    return setVariant("days");
  };

  const anchorDate = getAnchorDate(state);

  const minMaxLengthNotValid = minLength && maxLength && minLength > maxLength;

  const minLengthRange = minMaxLengthNotValid
    ? {}
    : getRangeWithOffset(anchorDate, minLength);

  const maxLengthRange = minMaxLengthNotValid
    ? {}
    : getRangeWithOffset(anchorDate, maxLength);

  return (
    <I18nProvider locale={locale.toString()}>
      <div
        {...ariaProps.calendarProps}
        ref={calendarRef}
        css={{
          boxShadow: uiShadows.bottomNormal,
          ...cssFns.border({ radius: size === "s" ? "13px" : "16px" }),
          ...cssFns.padding("8px"),
          backgroundColor: uiColors.background,
        }}
      >
        <Navigation
          size={size}
          ariaProps={ariaProps}
          state={state}
          variant={variant}
          changeVariant={() => {
            changeVariant();
            const anchorDate = getAnchorDate(state);
            if (anchorDate !== null) {
              return;
            }
            state.setFocusedDate(originalFocusedDate);
          }}
        />

        <div
          css={{
            ...(size !== "s" && {
              ...cssFns.margin("0", "8px"),
              marginBlockStart: "8px",
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: uiColors.line,
            }),
          }}
        >
          {variant === "days" && (
            <CalendarDaysGrid
              calendarSize={size}
              state={state}
              tooltip={tooltip}
              minLengthRange={minLengthRange}
              maxLengthRange={maxLengthRange}
            />
          )}

          {variant === "month" && (
            <CalendarMonthGrid
              calendarSize={size}
              state={state}
              switchToDate={() => {
                setVariant("days");
              }}
            />
          )}

          {variant === "year" && (
            <CalendarYearGrid
              calendarSize={size}
              state={state}
              switchToDate={() => {
                setVariant("month");
              }}
            />
          )}
        </div>
        {presets}
      </div>
    </I18nProvider>
  );
};

export const CalendarButton = (props: AriaButtonProps) => {
  return (
    <FieldButton {...props}>
      <icons.Calendar />
    </FieldButton>
  );
};

export const CalendarDialog = ({
  state,
  triggerRef,
  ariaDialogProps,
  children,
}: {
  state: OverlayTriggerState;
  triggerRef: RefObject<Element>;
  ariaDialogProps: AriaDialogProps;
  children: ReactNode;
}) => {
  return (
    <Popover state={state} triggerRef={triggerRef}>
      <Dialog ariaDialogProps={ariaDialogProps}>{children}</Dialog>
    </Popover>
  );
};

const Popover = ({
  triggerRef,
  state,
  children,
}: {
  triggerRef: RefObject<Element>;
  state: OverlayTriggerState;
  children: ReactNode;
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      placement: "bottom start",
      triggerRef,
      popoverRef,
      offset: 8,
    },
    state,
  );

  return (
    <Overlay>
      <div
        {...underlayProps}
        css={{
          position: "fixed",
          ...cssFns.inset("0"),
        }}
      />
      <div {...popoverProps} ref={popoverRef}>
        {children}
      </div>
    </Overlay>
  );
};

const Dialog = ({
  ariaDialogProps,
  children,
}: {
  ariaDialogProps: AriaDialogProps;
  children: ReactNode;
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog(ariaDialogProps, dialogRef);

  return (
    <div {...dialogProps} ref={dialogRef}>
      {children}
    </div>
  );
};

const NavigateButton = ({
  children,
  calendarSize,
  ...props
}: AriaButtonProps & { children: ReactNode; calendarSize: "s" | "m" }) => {
  const uiColors = useUiColors();

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  return (
    <button
      {...mergeProps(focusProps, buttonProps)}
      ref={ref}
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(calendarSize === "s"
          ? {
              width: "32px",
              height: "32px",
              ...cssFns.border({ width: "0", style: "none", radius: "50%" }),
            }
          : {
              width: "48px",
              height: "48px",
              ...cssFns.border({
                width: "1px",
                style: "solid",
                color: uiColors.line,
                radius: "50%",
              }),
            }),
        backgroundColor: uiColors.background,
        color: uiColors.text,
        outlineColor: uiColors.text,
        cursor: props.isDisabled ? "default" : "pointer",
        opacity: props.isDisabled ? "0.5" : "1",
        boxShadow: isFocused
          ? "inset 0 0 0 2px " + uiColors.focus
          : "0 0 0 0 transparent",
        outlineStyle: "none",
      }}
    >
      {children}
    </button>
  );
};

const Navigation = ({
  size,
  ariaProps,
  state,
  variant,
  changeVariant,
}: {
  size: "s" | "m";
  ariaProps: CalendarAria;
  state: CommonCalendarState;
  variant: Variant;
  changeVariant: () => void;
}) => {
  const message = useMessage();
  const locale = useLocale();
  const direction = locale.textInfo.direction;

  if (variant === "month") {
    return (
      <div>
        <NavigationMonths
          size={size}
          nextButtonProps={ariaProps.nextButtonProps}
          prevButtonProps={ariaProps.prevButtonProps}
          state={state}
          changeVariant={() => {
            changeVariant();
          }}
        />
      </div>
    );
  }

  if (variant === "year") {
    return (
      <div>
        <NavigationYears
          size={size}
          nextButtonProps={ariaProps.nextButtonProps}
          prevButtonProps={ariaProps.prevButtonProps}
          state={state}
          changeVariant={() => {
            changeVariant();
          }}
        />
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        direction,
      }}
    >
      <NavigateButton calendarSize={size} {...ariaProps.prevButtonProps}>
        {direction === "ltr" ? <icons.ArrowLeft /> : <icons.ArrowRight />}
      </NavigateButton>

      <TitleButton
        label={message({
          id: "e562ef38-79a2-401e-81ca-2a7533cf3d3a",
          context: "Calendar. Title button. Label",
          default: "Month",
        })}
        size={size}
        state={state}
        variant="days"
        onPress={changeVariant}
      />

      <NavigateButton calendarSize={size} {...ariaProps.nextButtonProps}>
        {direction === "ltr" ? <icons.ArrowRight /> : <icons.ArrowLeft />}
      </NavigateButton>
    </div>
  );
};

const NavigationMonths = ({
  size,
  state,
  changeVariant,
  nextButtonProps,
  prevButtonProps,
}: {
  size: "s" | "m";
  state: CommonCalendarState;
  changeVariant: () => void;
  nextButtonProps: AriaButtonProps;
  prevButtonProps: AriaButtonProps;
}) => {
  const locale = useLocale();
  const message = useMessage();
  const direction = locale.textInfo.direction;
  const navigationYearOffset = useYearNavigation(state);
  const { year } = state.visibleRange.start;
  const maxYear = state.maxValue?.year;
  const minYear = state.minValue?.year;

  const hasNextPage = maxYear === undefined ? true : year < maxYear;
  const hasPrevPage = minYear === undefined ? true : year > minYear;

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        direction,
      }}
    >
      <NavigateButton
        calendarSize={size}
        onPress={() => {
          navigationYearOffset(-1);
        }}
        aria-label={prevButtonProps["aria-label"]}
        isDisabled={!hasPrevPage}
      >
        {direction === "ltr" ? <icons.ArrowLeft /> : <icons.ArrowRight />}
      </NavigateButton>
      <TitleButton
        label={message({
          id: "a81c9a77-8e1c-4c74-8337-a820cec856d2",
          context: "Calendar. Title button. Label",
          default: "Year",
        })}
        variant="month"
        state={state}
        onPress={changeVariant}
        size={size}
      />
      <NavigateButton
        calendarSize={size}
        onPress={() => {
          navigationYearOffset(1);
        }}
        aria-label={nextButtonProps["aria-label"]}
        isDisabled={!hasNextPage}
      >
        {direction === "ltr" ? <icons.ArrowRight /> : <icons.ArrowLeft />}
      </NavigateButton>
    </div>
  );
};

const NavigationYears = ({
  size,
  nextButtonProps,
  prevButtonProps,
  state,
  changeVariant,
}: {
  size: "s" | "m";
  nextButtonProps: AriaButtonProps;
  prevButtonProps: AriaButtonProps;
  state: CommonCalendarState;
  changeVariant: () => void;
}) => {
  const locale = useLocale();
  const message = useMessage();
  const direction = locale.textInfo.direction;
  const navigationYearOffset = useYearNavigation(state);
  const { year } = state.visibleRange.start;
  const maxYear = state.maxValue?.year;
  const minYear = state.minValue?.year;

  const hasNextPage = maxYear === undefined ? true : year < maxYear;
  const hasPrevPage = minYear === undefined ? true : year > minYear;

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        direction,
      }}
    >
      <NavigateButton
        calendarSize={size}
        onPress={() => {
          navigationYearOffset(-12);
        }}
        aria-label={prevButtonProps["aria-label"]}
        isDisabled={!hasPrevPage}
      >
        {direction === "ltr" ? <icons.ArrowLeft /> : <icons.ArrowRight />}
      </NavigateButton>
      <TitleButton
        label={message({
          id: "3376afa4-74ab-40a0-932c-83d49652d803",
          context: "Calendar. Title button. Label",
          default: "Day",
        })}
        variant="year"
        state={state}
        onPress={changeVariant}
        size={size}
      />
      <NavigateButton
        calendarSize={size}
        onPress={() => {
          navigationYearOffset(12);
        }}
        aria-label={nextButtonProps["aria-label"]}
        isDisabled={!hasNextPage}
      >
        {direction === "ltr" ? <icons.ArrowRight /> : <icons.ArrowLeft />}
      </NavigateButton>
    </div>
  );
};

const TitleButtonYears = ({
  size,
  year,
}: {
  size: "s" | "m";
  year: string;
}) => {
  const typo = useTypo();
  const range = getYearsRange(Number(year));
  const locale = useLocale();
  const direction = locale.textInfo.direction;

  if (size === "s") {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          ...typo({
            level: "caption1",
            density: "tight",
            weight: "medium",
          }),
        }}
      >
        {range[0]} - {range[range.length - 1]}
        <span
          css={{
            marginInlineStart: "-4px",
            marginInlineEnd: "-4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {direction === "ltr" ? (
            <icons.ChevronRight width={"20px"} height={"20px"} />
          ) : (
            <icons.ChevronLeft width={"20px"} height={"20px"} />
          )}
        </span>
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        ...typo({
          level: "body2",
          density: "tight",
          weight: "regular",
        }),
      }}
    >
      <span>
        {range[0]} - {range[range.length - 1]}
      </span>
      <span
        css={{
          marginInlineStart: "-4px",
          marginInlineEnd: "-4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {direction === "ltr" ? <icons.ChevronRight /> : <icons.ChevronLeft />}
      </span>
    </div>
  );
};

const TitleButtonMonths = ({
  size,
  year,
}: {
  size: "s" | "m";
  year: string;
}) => {
  const typo = useTypo();
  const locale = useLocale();
  const direction = locale.textInfo.direction;

  if (size === "s") {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          ...typo({
            level: "caption1",
            density: "tight",
            weight: "medium",
          }),
        }}
      >
        {year}
        <span
          css={{
            marginInlineStart: "-4px",
            marginInlineEnd: "-4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {direction === "ltr" ? (
            <icons.ChevronRight width={"20px"} height={"20px"} />
          ) : (
            <icons.ChevronLeft width={"20px"} height={"20px"} />
          )}
        </span>
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        ...typo({
          level: "body2",
          density: "tight",
          weight: "regular",
        }),
      }}
    >
      <span>{year}</span>
      <span
        css={{
          marginInlineStart: "-4px",
          marginInlineEnd: "-4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {direction === "ltr" ? <icons.ChevronRight /> : <icons.ChevronLeft />}
      </span>
    </div>
  );
};

const TitleButtonDays = ({
  size,
  plainDate,
}: {
  size: "s" | "m";
  plainDate: Temporal.PlainDate;
}) => {
  const typo = useTypo();
  const locale = useLocale();
  const { formatDateTime } = useFormat();
  const direction = locale.textInfo.direction;

  const month = formatDateTime(plainDate, {
    month: "long",
  });

  const monthShortName = formatDateTime(plainDate, {
    month: "short",
  });

  const year = formatDateTime(plainDate, {
    year: "numeric",
  });

  if (size === "s") {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          ...typo({
            level: "caption1",
            density: "tight",
            weight: "medium",
          }),
        }}
      >
        {`${ucText(monthShortName)} ${year}`}
        <span
          css={{
            marginInlineStart: "-4px",
            marginInlineEnd: "-4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {direction === "ltr" ? (
            <icons.ChevronRight width={"20px"} height={"20px"} />
          ) : (
            <icons.ChevronLeft width={"20px"} height={"20px"} />
          )}
        </span>
      </div>
    );
  }

  return (
    <>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          ...typo({
            level: "body2",
            density: "tight",
            weight: "regular",
          }),
        }}
      >
        <span>{ucText(month)}</span>
        <span
          css={{
            marginInlineStart: "-4px",
            marginInlineEnd: "-4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {direction === "ltr" ? <icons.ChevronRight /> : <icons.ChevronLeft />}
        </span>
      </div>

      <div
        css={{
          ...typo({
            level: "caption1",
            density: "tight",
            weight: "regular",
          }),
        }}
      >
        {year}
      </div>
    </>
  );
};

const TitleButton = ({
  size,
  variant,
  onPress,
  state,
  label,
}: {
  variant: Variant;
  onPress: () => void;
  state: CommonCalendarState;
  size: "s" | "m";
  label: string;
}) => {
  const uiColors = useUiColors();
  const { formatDateTime } = useFormat();
  const plainDate = calendarDateToPlainDate(state.visibleRange.start);

  const year = formatDateTime(plainDate, {
    year: "numeric",
  });

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress,
    },
    ref,
  );

  const { focusProps, isFocused } = useFocusRing({
    within: true,
  });

  return (
    <button
      ref={ref}
      {...mergeProps(buttonProps, focusProps)}
      aria-label={label}
      css={{
        outlineStyle: "none",
        boxSizing: "border-box",
        position: "relative",
        backgroundColor: "transparent",
        cursor: "pointer",
        color: uiColors.text,
        ...cssFns.border({ style: "none", radius: "8px" }),
        ...cssFns.padding("8px", "8px"),
        ...cssFns.boxShadow(
          isFocused && {
            inset: true,
            spreadRadius: "2px",
            color: uiColors.focus,
          },
        ),
      }}
    >
      {variant === "year" && <TitleButtonYears size={size} year={year} />}
      {variant === "month" && <TitleButtonMonths size={size} year={year} />}
      {variant === "days" && (
        <TitleButtonDays size={size} plainDate={plainDate} />
      )}
    </button>
  );
};

const CalendarDaysGrid = ({
  state,
  calendarSize,
  tooltip,
  maxLengthRange,
  minLengthRange,
}: {
  state: CommonCalendarState;
  calendarSize: "s" | "m";
  tooltip?: (state: { value: Temporal.PlainDate }) => string | undefined;
  maxLengthRange: CalendarRange;
  minLengthRange: CalendarRange;
}) => {
  const locale = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid({}, state);
  const weeksInMonth = getWeeksInMonth(
    state.visibleRange.start,
    locale.toString(),
  );

  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <HeaderCell key={index} day={day} calendarSize={calendarSize} />
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date: CalendarDate | null, i: number) =>
                date && isInsideMonth(date, state) ? (
                  <BodyCell
                    calendarSize={calendarSize}
                    key={i}
                    state={state}
                    date={date}
                    tooltip={tooltip}
                    maxLengthRange={maxLengthRange}
                    minLengthRange={minLengthRange}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CalendarMonthGrid = ({
  state,
  switchToDate,
  calendarSize,
}: {
  state: CommonCalendarState;
  switchToDate: () => void;
  calendarSize: "s" | "m";
}) => {
  const locale = useLocale();
  const months = useMemo(() => getMonthsInYear(locale.toString()), [locale]);
  const [hoverDateKey, setHoverDateKey] = useState<string | null>(null);
  const minValue = state.minValue;
  const maxValue = state.maxValue;
  const anchorDate = getAnchorDate(state);
  const visibleRange = state.visibleRange.start;
  const currentDateKey = getDateKey(visibleRange.year, visibleRange.month);

  const dateNavigation = useDateNavigation(state);
  const selectedMonth = useSelectedMonth(state);
  const anchorMonth = useAnchorMonth(anchorDate, hoverDateKey);

  const options: CalendarGridItem[] = months.map((month, i) => {
    const monthNumber = i + 1;
    const key = getDateKey(visibleRange.year, monthNumber);

    const selected =
      anchorDate !== null ? anchorMonth[key] : selectedMonth[key];

    const disabled = !dateInRange({
      year: visibleRange.year,
      month: monthNumber,
      maxValue,
      minValue,
    });

    const label = ucText(
      getMonthByNumber(
        monthNumber,
        locale.toString(),
        calendarSize === "s" ? "short" : "long",
      ),
    );

    return {
      key,
      label,
      disabled,
      selected,
      ariaLabel: `${month}, ${visibleRange.year}`,
    };
  });

  const handleChange = (keys: string[]) => {
    const [date] = keys;
    if (!date) {
      // Workaround for https://github.com/adobe/react-spectrum/issues/1513
      // Corrects the triggering of clicks on elements under the overlay
      setTimeout(switchToDate, 0);
      return;
    }

    const [year, monthId] = date.split(".");
    const value: DateValue = new CalendarDate(
      Number(year),
      Number(monthId),
      state.focusedDate.day,
    );
    state.setFocusedDate(value);
    // Workaround for https://github.com/adobe/react-spectrum/issues/1513
    // Corrects the triggering of clicks on elements under the overlay
    setTimeout(switchToDate, 0);
  };

  const onArrowNavigation = (
    keyCode: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight",
    listState: ListState<CalendarGridItem>,
  ) => {
    const { selectionManager } = listState;
    const monthId = selectionManager.focusedKey?.toString().split(".")[1];

    const monthNumber = Number(monthId) - 1;
    const row = Math.floor(monthNumber / 3) + 1;
    const visibleRangeStart = state.visibleRange.start;
    const currentMonthNumber = monthNumber + 1;
    const currentDayNumber = visibleRangeStart.day;
    const currentYearNumber = visibleRangeStart.year;

    if (keyCode === "ArrowLeft" && row === 1) {
      selectionManager.setFocusedKey(`${currentYearNumber}.12`);
      dateNavigation(
        currentYearNumber - 1,
        currentMonthNumber,
        currentDayNumber,
      );
      return;
    }

    if (keyCode === "ArrowRight" && row === 4) {
      selectionManager.setFocusedKey(`${currentYearNumber}.01`);
      dateNavigation(
        currentYearNumber + 1,
        currentMonthNumber,
        currentDayNumber,
      );
      return;
    }
  };

  return (
    <div
      css={{
        paddingBlockStart: "12px",
        width: calendarSize === "m" ? "325px" : "212px",
      }}
    >
      <CalendarGrid
        label={String(visibleRange.year)}
        columns={3}
        size={calendarSize}
        initialFocusedKey={String(currentDateKey)}
        options={options}
        onChange={handleChange}
        onArrowNavigation={onArrowNavigation}
        shouldFocusOnHover={anchorDate !== null}
        onItemFocus={(key) => {
          setHoverDateKey(String(key));
        }}
      />
    </div>
  );
};

const CalendarYearGrid = ({
  state,
  switchToDate,
  calendarSize,
}: {
  state: CommonCalendarState;
  switchToDate: () => void;
  calendarSize: "s" | "m";
}) => {
  const [hoverDateKey, setHoverDateKey] = useState<string | null>(null);
  const minValue = state.minValue;
  const maxValue = state.maxValue;
  const anchorDate = getAnchorDate(state);

  const visibleRange = state.visibleRange.start;
  const currentDateKey = visibleRange.year;

  const selectedYears = useSelectedYears(state);
  const anchorYear = useAnchorYear(anchorDate, hoverDateKey);

  const options: CalendarGridItem[] = getYearsRange(visibleRange.year).map(
    (year) => {
      const selected = anchorDate ? anchorYear[year] : selectedYears[year];
      const disabled =
        Boolean(maxValue?.year && maxValue.year < year) ||
        Boolean(minValue?.year && minValue.year > year);

      return {
        key: String(year),
        label: String(year),
        ariaLabel: String(year),
        disabled,
        selected,
      };
    },
  );

  const handleChange = (keys: string[]) => {
    const [year] = keys;
    if (!year) {
      // Workaround for https://github.com/adobe/react-spectrum/issues/1513
      // Corrects the triggering of clicks on elements under the overlay
      setTimeout(switchToDate, 0);
      return;
    }

    const value: DateValue = new CalendarDate(
      Number(year),
      state.focusedDate.month,
      state.focusedDate.day,
    );
    state.setFocusedDate(value);

    // Workaround for https://github.com/adobe/react-spectrum/issues/1513
    // Corrects the triggering of clicks on elements under the overlay
    setTimeout(switchToDate, 0);
  };

  const onArrowNavigation = (
    keyCode: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight",
    listState: ListState<CalendarGridItem>,
  ) => {
    const { selectionManager } = listState;

    if (selectionManager.focusedKey === null) {
      return;
    }

    if (keyCode === "ArrowRight") {
      const year = Number(selectionManager.focusedKey.toString());

      const value: DateValue = new CalendarDate(
        year + 1,
        state.visibleRange.start.month,
        state.visibleRange.start.day,
      );

      state.setFocusedDate(value);
      return;
    }

    if (keyCode === "ArrowLeft") {
      const year = Number(selectionManager.focusedKey.toString());

      const value: DateValue = new CalendarDate(
        year - 1,
        state.visibleRange.start.month,
        state.visibleRange.start.day,
      );

      state.setFocusedDate(value);
      return;
    }
  };

  return (
    <div
      css={{
        paddingBlockStart: "12px",
        width: calendarSize === "m" ? "325px" : "212px",
      }}
    >
      <CalendarGrid
        label={String(visibleRange.year)}
        columns={3}
        size={calendarSize}
        initialFocusedKey={String(currentDateKey)}
        options={options}
        onChange={handleChange}
        onArrowNavigation={onArrowNavigation}
        shouldFocusOnHover={anchorDate !== null}
        onItemFocus={(key) => {
          setHoverDateKey(String(key));
        }}
      />
    </div>
  );
};

const useCellSize = (calendarSize: "s" | "m") => {
  const isMobile = useIsMobile();

  return calendarSize === "s"
    ? {
        width: 28,
        height: 28,
        gap: 2,
      }
    : {
        width: isMobile ? 40 : 44,
        height: isMobile ? 40 : 44,
        gap: 2,
      };
};

const HeaderCell = ({
  day,
  calendarSize,
}: {
  day: string;
  calendarSize: "s" | "m";
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const { width } = useCellSize(calendarSize);

  return (
    <th
      css={{
        ...cssFns.padding("0"),
      }}
    >
      <div
        css={{
          width: `${width}px`,
          height: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...typo({
            level: "caption1",
            density: "normal",
            weight: "regular",
          }),
          color: uiColors.textMinor,
        }}
      >
        {day}
      </div>
    </th>
  );
};

const BodyCell = ({
  calendarSize,
  state,
  date,
  tooltip,
  minLengthRange,
  maxLengthRange,
}: {
  calendarSize: "s" | "m";
  state: CommonCalendarState;
  date: CalendarDate;
  tooltip?: (state: { value: Temporal.PlainDate }) => string | undefined;
  minLengthRange: CalendarRange;
  maxLengthRange: CalendarRange;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const [tooltipMessage, setTooltipMessage] = useState("");
  const { width, height } = useCellSize(calendarSize);
  const anchorDate = getAnchorDate(state);
  const notAllowed = getAllowedStatus({ date, minLengthRange, maxLengthRange });

  const tz = useTz();
  const isToday = today(tz.id).compare(date) === 0;
  const ref = useRef<HTMLDivElement>(null);

  const {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const {
    triggerProps: tooltipTriggerProps,
    tooltipProps,
    state: tooltipState,
  } = useTooltip(ref, {
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setTooltipMessage("");
        return;
      }

      const message = tooltip?.({
        value: Temporal.PlainDate.from({
          day: date.day,
          month: date.month,
          year: date.year,
        }),
      });

      setTooltipMessage(message || "");
    },
  });

  const tooltipVisible = tooltipState.isOpen && tooltipMessage;

  const onPress = (e: PointerEvent<HTMLDivElement>) => {
    if (!buttonProps.onPointerUp) {
      return;
    }

    if (anchorDate === null || notAllowed === false) {
      buttonProps.onPointerUp(e);
      return;
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLElement>) => {
    if (!buttonProps.onKeyDown) {
      return;
    }

    if (e.key !== "Enter") {
      buttonProps.onKeyDown(e);
      return;
    }

    if (anchorDate === null || notAllowed === false) {
      buttonProps.onKeyDown(e);
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const commonCellProps = mergeProps(cellProps, tooltipTriggerProps);
  const disabled = isDisabled || isUnavailable || notAllowed;
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  const overLengthRange =
    (maxLengthRange.minLength && date.compare(maxLengthRange.minLength) < 0) ||
    (maxLengthRange.maxLength && date.compare(maxLengthRange.maxLength) > 0);

  const onPointerEnter = (e: PointerEvent<HTMLDivElement>) => {
    if (overLengthRange || !buttonProps.onPointerEnter) {
      return;
    }

    buttonProps.onPointerEnter(e);
  };

  return (
    <td
      {...commonCellProps}
      aria-disabled={cellProps["aria-disabled"] || notAllowed}
      aria-describedby={
        tooltipVisible ? commonCellProps["aria-describedby"] : undefined
      }
      css={{
        ...cssFns.padding("0"),
      }}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        // Workaround - create custom logic to notAllowed
        // forbid select value if not allowed by count limit
        onPointerUp={onPress}
        onKeyDown={onKeyPress}
        onPointerEnter={onPointerEnter}
        aria-disabled={buttonProps["aria-disabled"] || notAllowed}
        ref={ref}
        css={{
          width: `${width}px`,
          height: `${height}px`,
          ...(calendarSize === "s"
            ? {
                ...cssFns.border({ radius: "8px" }),
                ...typo({
                  level: "caption1",
                  density: "tight",
                  weight: "regular",
                }),
              }
            : {
                ...cssFns.border({ radius: "14px" }),
                ...typo({
                  level: "body2",
                  density: "normal",
                  weight: "regular",
                }),
              }),

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            isSelected && !overLengthRange
              ? uiColors.controlMain
              : isToday
                ? uiColors.backgroundMinor
                : undefined,
          boxShadow: isFocused
            ? "inset 0 0 0 2px " + uiColors.focus
            : "0 0 0 0 transparent",
          outlineStyle: "none",
          color:
            isSelected && !overLengthRange
              ? uiColors.textOnControlMain
              : disabled
                ? uiColors.textMinor
                : uiColors.text,
          cursor: disabled ? "default" : "pointer",
          outlineWidth: "none",
        }}
      >
        {formattedDate}
      </div>

      {tooltipVisible && (
        <Tooltip
          variant="info"
          state={tooltipState}
          tooltipProps={tooltipProps}
          targetRef={ref}
          offset={4}
        >
          <div css={{ maxWidth: `${width * 4}px` }}>{tooltipMessage}</div>
        </Tooltip>
      )}
    </td>
  );
};

const calendarDateToPlainDate = (date: DateValue): Temporal.PlainDate => {
  return Temporal.PlainDate.from({
    day: date.day,
    month: date.month,
    year: date.year,
  });
};

const isInsideMonth = (date: CalendarDate, state: CommonCalendarState) => {
  return (
    date.compare(state.visibleRange.start) >= 0 &&
    date.compare(state.visibleRange.end) <= 0
  );
};

const PresetContainer = ({
  size,
  children,
}: {
  size: "s" | "m";
  children: ReactNode;
}) => {
  const uiColors = useUiColors();
  const daysInWeek = 7;
  const { width, gap } = useCellSize(size);

  return (
    <div
      css={{
        ...cssFns.padding("4px", "2px"),
        marginBlockStart: "8px",
      }}
    >
      <div
        css={{
          paddingBlockStart: "12px",
          ...cssFns.borderBlockStart({
            width: "1px",
            style: "solid",
            color: uiColors.line,
          }),
        }}
      >
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            ...cssFns.gap("4px"),
            maxWidth: `${width * daysInWeek + gap * (daysInWeek - 1)}px`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const PresetButton = ({
  text,
  onPress,
}: {
  text: string;
  onPress: () => void;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress }, ref);
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      css={{
        appearance: "none",
        ...cssFns.margin("0"),
        backgroundColor: "transparent",
        display: "inline-grid",
        ...cssFns.placeContent("center"),
        isolation: "isolate",
        color: uiColors.text,
        cursor: "pointer",
        height: "26px",
        ...cssFns.padding("0", "8px"),
        ...cssFns.border({
          style: "solid",
          width: "1px",
          color: uiColors.controlMinor,
          radius: "8px",
        }),
        outlineStyle: "none",
        ...typo({ level: "caption2", density: "tight", weight: "regular" }),
        ...cssFns.boxShadow(
          isFocused && {
            inset: true,
            spreadRadius: "2px",
            color: uiColors.focus,
          },
        ),
      }}
    >
      {text}
    </button>
  );
};

const getDateValue = (state: CommonCalendarState) => {
  let start: null | DateValue = null;
  let end: null | DateValue = null;

  const value = (state as RangeCalendarState).value;

  if (value && value.hasOwnProperty("start")) {
    start = value.start;
  } else if (value) {
    start = (state as CalendarState).value;
  }

  if (value && value.hasOwnProperty("end")) {
    end = value.end;
  } else if (state.value) {
    end = (state as CalendarState).value;
  }

  return {
    start,
    end,
  };
};

const useSelectedMonth = (
  state: CommonCalendarState,
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  const { start, end } = getDateValue(state);

  if (start === null) {
    return result;
  }

  if (end === null) {
    const key = getDateKey(start.year, start.month);
    result[key] = true;
    return result;
  }

  let startDate = new Date(start.year, start.month - 1, 1);
  const endDate = new Date(end.year, end.month - 1, 1);

  // Create selected date keys (YYYY.MM)
  while (startDate <= endDate) {
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const key = getDateKey(year, month);
    result[key] = true;
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return result;
};

const useSelectedYears = (
  state: CommonCalendarState,
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  const { start, end } = getDateValue(state);

  if (start === null) {
    return result;
  }

  if (end === null) {
    result[start.year] = true;
    return result;
  }

  let startDate = new Date(start.year, start.month - 1, 1);
  const endDate = new Date(end.year, end.month - 1, 1);

  while (startDate <= endDate) {
    const year = startDate.getFullYear();
    result[year] = true;
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return result;
};

const useYearNavigation = (
  state: CommonCalendarState,
): ((offset: number) => void) => {
  return (offset: number) => {
    const visibleRange = state.visibleRange;
    const value: DateValue = new CalendarDate(
      visibleRange.start.year + offset,
      visibleRange.start.month,
      visibleRange.start.day,
    );
    state.setFocusedDate(value);
  };
};

const useDateNavigation = (
  state: CommonCalendarState,
): ((year: number, month: number, day: number) => void) => {
  return (year: number, month: number, day: number) => {
    const value: DateValue = new CalendarDate(year, month, day);
    state.setFocusedDate(value);
  };
};

const getRangeWithOffset = (
  date: CalendarDate | null,
  count?: number,
): CalendarRange => {
  if (date === null || count === undefined || count < 0) {
    return {};
  }

  return {
    minLength: count >= 0 ? date.copy().add({ days: 1 - count }) : undefined,
    maxLength: count >= 0 ? date.copy().add({ days: count - 1 }) : undefined,
  };
};

const getAllowedStatus = ({
  date,
  minLengthRange,
  maxLengthRange,
}: {
  date: CalendarDate;
  minLengthRange: CalendarRange;
  maxLengthRange: CalendarRange;
}) => {
  let notAllowed = false;
  if (
    minLengthRange.maxLength &&
    minLengthRange.maxLength.compare(date) > 0 &&
    minLengthRange.minLength &&
    minLengthRange.minLength.compare(date) < 0
  ) {
    notAllowed = true;
  }

  if (
    (maxLengthRange.minLength && date.compare(maxLengthRange.minLength) < 0) ||
    (maxLengthRange.maxLength && date.compare(maxLengthRange.maxLength) > 0)
  ) {
    notAllowed = true;
  }

  return notAllowed;
};

const getAnchorDate = (state: CommonCalendarState): CalendarDate | null => {
  if (!state.hasOwnProperty("anchorDate")) {
    return null;
  }

  return (state as RangeCalendarState).anchorDate;
};

const ucText = (text: string): string => {
  let chars = text.toLocaleLowerCase().split("");
  chars[0] = chars[0]?.toLocaleUpperCase() || "";
  return chars.join("");
};
