import type { Temporal } from "@js-temporal/polyfill";

import type { ComboBoxOption, ComboBoxState } from "@superweb/ui";

import { useMessage } from "#internal/intl";

export const useErrorMessages = () => {
  const message = useMessage();

  return {
    requiredField: message({
      id: "bada154b-5f6c-4f76-a6f7-794358e95802",
      context: "Fleet forms. Validation Error",
      default: "Required field",
    }),
    invalidPhone: message({
      id: "b00c1dad-2cdd-4e29-888e-c0b74510a3e5",
      context: "Fleet forms. Validation Error",
      default: "Phone number format is invalid",
    }),
    invalidCarNumber: message({
      id: "c1be4f67-aae6-4c19-b93e-ae5c5dc7d52c",
      context: "Fleet forms. Validation Error",
      default: "Invalid vehicle license plate number",
    }),
    invalidDriverLicense: message({
      id: "86768ee6-fc12-46a9-b973-b41f83654393",
      context: "Fleet forms. Validation Error",
      default: "Invalid driver's license number",
    }),
    invalidDriverLicenseWithTemplates: (formats: string) =>
      message({
        id: "8f8d7aaf-0802-48c3-97e5-5467f65baaf6",
        context: "Fleet forms. Validation Error",
        default: "Invalid driver's license number. Valid formats: {formats}",
        values: { formats },
      }),
    invalidDate: message({
      id: "a21b549f-36fe-4a11-ba34-2322cf386a5b",
      context: "Fleet forms. Validation Error",
      default: "Invalid date",
    }),
    default: message({
      id: "1ec98827-d562-4b6b-a99e-21a14889b7f1",
      context: "Fleet forms. Validation Error",
      default: "Failed to load. Try again later or reload the page",
    }),
  };
};

export const useSubmitErrorMessages = (): Record<string, string> => {
  const message = useMessage();

  const phoneVerificationErrorText = message({
    id: "ef4a3444-b811-4187-8693-b7385892f725",
    context: "Fleet forms. Submit Error",
    default: "Error verifying phone number",
  });

  return {
    invalid_personal_data: message({
      id: "9fa66ebe-7137-4697-a489-d7611877a176",
      context: "Fleet forms. Submit Error",
      default: "Invalid name",
    }),
    invalid_phone_number: message({
      id: "3b0e34f0-8ef8-45e4-9844-9662f1b9c6a6",
      context: "Fleet forms. Submit Error",
      default: "Invalid phone number format",
    }),
    invalid_car_number: message({
      id: "736184a8-d5a7-4cca-8057-84f082b04a07",
      context: "Fleet forms. Submit Error",
      default: "Invalid vehicle license plate number",
    }),
    invalid_driver_license: message({
      id: "66addcf7-8c55-428c-9f11-061befa2e15e",
      context: "Fleet forms. Submit Error",
      default: "Invalid driver's license number",
    }),
    invalid_issue_date: message({
      id: "d483ac1e-8ed1-4ffa-be60-e3c5b5401fb4",
      context: "Fleet forms. Submit Error",
      default: "Invalid driver's license issue date",
    }),
    invalid_expiry_date: message({
      id: "7f65beae-821e-4750-a1f0-dbba933a738a",
      context: "Fleet forms. Submit Error",
      default: "Invalid driver's license expiration date",
    }),
    need_confirmation_data: phoneVerificationErrorText,
    inn_required: message({
      id: "023af382-ef0c-4998-a7db-4c3f202168c6",
      context: "Fleet forms. Submit Error",
      default: "Taxpayer ID required",
    }),
    inn_verification_failed: message({
      id: "d1c7d103-e3fb-4037-a934-caca2f8c3fd9",
      context: "Fleet forms. Submit Error",
      default:
        "Taxpayer ID not found. The number may not be entered correctly, or it's not available yet in the Federal Tax Service. If the individual entrepreneur was registered less than 5 days ago, the data is not available yet. Enter the Taxpayer ID again or try later.",
    }),
    verification_phone_failed: phoneVerificationErrorText,
    phone_verification_fns_failed: message({
      id: "35fc3eeb-1e1a-438b-bdf4-c8f0cd4bb8d0",
      context: "Fleet forms. Submit Error",
      default: "Error validating with the Federal Tax Service",
    }),
    contractor_already_exists: message({
      id: "839aee99-8990-44fb-b649-36baf0463fda",
      context: "Fleet forms. Submit Error",
      default: "You're already registered with this partner company",
    }),
  };
};

export const useValidate = () => {
  const errorMessages = useErrorMessages();

  const validateTextField = (value: string) => {
    const trimmed = value.trim();
    return trimmed.length === 0 ? errorMessages.requiredField : undefined;
  };
  const validateComboboxField = (value: ComboBoxState<ComboBoxOption>) => {
    return value.value ? undefined : errorMessages.requiredField;
  };
  const validatePhoneField = (
    value: string,
    options?: { isPhoneInvalid?: boolean },
  ) => {
    const trimmed = value.trim();
    if (trimmed.length === 0) return errorMessages.requiredField;
    return options?.isPhoneInvalid ? errorMessages.invalidPhone : undefined;
  };
  const validateDateField = (value?: Temporal.PlainDate) => {
    return value === undefined ? errorMessages.requiredField : undefined;
  };

  return {
    validateComboboxField,
    validateTextField,
    validatePhoneField,
    validateDateField,
  };
};
