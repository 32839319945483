/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type MutableRefObject,
  type ReactNode,
  type RefObject,
} from "react";
import { useHover } from "react-aria";
import useResizeObserver from "use-resize-observer";

import { icons as fleetIcons } from "@fleet/ui";
import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { Button, useIsMobile } from "@superweb/ui";

import { useMessage } from "#intl";

import { useUiOptions } from "./ui-options-context";

const useMutationObserver = ({
  ref,
  onMutate,
  options = {},
  enabled = true,
}: {
  ref: RefObject<HTMLDivElement>;
  onMutate: MutationCallback;
  options?: MutationObserverInit;
  enabled?: boolean;
}) => {
  useEffect(() => {
    if (!ref.current || !enabled) return;
    const observer = new MutationObserver(onMutate);
    observer.observe(ref.current, options);
    return () => observer.disconnect();
  }, [enabled, onMutate, options, ref]);
};

export const RowWithScroll = ({
  children,
  disabledShadow = false,
  disabledAlign = false,
  scrollRef,
  alwaysShowArrows,
}: {
  children: ReactNode;
  disabledShadow?: boolean;
  disabledAlign?: boolean;
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
  alwaysShowArrows?: boolean;
}) => {
  const isMobile = useIsMobile();
  const message = useMessage();
  const { experimental } = useUiOptions();

  const innerRef = useRef<HTMLDivElement>(null);
  const ref = scrollRef ?? innerRef;
  const {
    textInfo: { direction },
  } = useLocale();

  const { hoverProps, isHovered } = useHover({});

  const shouldShowArrows = alwaysShowArrows || isMobile || isHovered;

  const [canScrollStart, setCanScrollStart] = useState(false);
  const [canScrollEnd, setCanScrollEnd] = useState(true);

  const checkScrollPosition = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    const scrollLeft = Math.abs(scrollContainer.scrollLeft);
    setCanScrollStart(scrollLeft > 2);
    setCanScrollEnd(
      scrollContainer.scrollWidth - scrollLeft - scrollContainer.clientWidth >
        2,
    );
  }, [ref]);

  const scrollEnd = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    const scrollLeft = Math.abs(scrollContainer.scrollLeft);
    const restOfScrollEnd =
      scrollContainer.scrollWidth - scrollLeft - scrollContainer.clientWidth;
    const scrollByLeft = Math.min(scrollContainer.clientWidth, restOfScrollEnd);
    ref.current.scrollBy({
      left: direction === "ltr" ? scrollByLeft : -scrollByLeft,
      behavior: "smooth",
    });
  }, [direction, ref]);

  const scrollStart = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    const scrollLeft = Math.abs(scrollContainer.scrollLeft);
    const scrollByLeft = Math.min(scrollLeft, scrollContainer.clientWidth);
    ref.current.scrollBy({
      left: direction === "ltr" ? -scrollByLeft : scrollByLeft,
      behavior: "smooth",
    });
  }, [direction, ref]);

  useLayoutEffect(() => {
    checkScrollPosition();
    if (!ref.current) return;
    const scrollContainer = ref.current;
    scrollContainer.addEventListener("scroll", checkScrollPosition);
    return () =>
      scrollContainer.removeEventListener("scroll", checkScrollPosition);
  }, [checkScrollPosition, ref]);

  useResizeObserver({
    ref,
    onResize: checkScrollPosition,
  });

  useMutationObserver({
    ref,
    onMutate: checkScrollPosition,
    options: {
      childList: true,
      subtree: true,
      characterData: true,
    },
    enabled: experimental?.checkScrollPositionWhenMutation,
  });

  return (
    <div
      {...hoverProps}
      css={{
        position: "relative",
        display: "grid",
        alignContent: disabledAlign ? undefined : "center",
      }}
    >
      <div
        ref={ref}
        css={{
          display: "flex",
          overflowX: "auto",
          ...cssFns.overscrollBehavior("contain"),
          scrollbarWidth: "none",
          ...(!disabledShadow && {
            maskImage: `linear-gradient(
              ${direction === "ltr" ? "to right" : "to left"},
              ${canScrollStart ? "transparent 0px, black 10px" : "black"},
              black calc(100% - 10px),
              ${canScrollEnd ? "transparent 100%" : "black"}
            )`,
          }),
        }}
        __experimental_webkitScrollbarCss={{ display: "none" }}
      >
        {children}
      </div>
      {shouldShowArrows && canScrollStart && (
        <div
          css={{
            position: "absolute",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={message({
              id: "d8bf0553-09b8-4b50-8862-6cef99f7b7d8",
              context: "Row with scroll. Arrow button",
              default: "Scroll to previous",
            })}
            icon={fleetIcons.ArrowInlineStart}
            onPress={() => {
              scrollStart();
            }}
          />
        </div>
      )}
      {shouldShowArrows && canScrollEnd && (
        <div
          css={{
            position: "absolute",
            insetInlineEnd: "0px",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={message({
              id: "a62e35e8-02df-4eaa-9e3f-7f09b36f5f43",
              context: "Row with scroll. Arrow button",
              default: "Scroll to next",
            })}
            icon={fleetIcons.ArrowInlineEnd}
            onPress={() => {
              scrollEnd();
            }}
          />
        </div>
      )}
    </div>
  );
};
