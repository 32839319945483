/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import { Flags as BaseFlags } from "@superweb/flags";

export const Flags = ({ children }: { children: ReactNode }) => {
  return (
    <BaseFlags
      value={{
        "7fd41e03-8f0f-4153-b9ed-a0e613ed5500": Boolean(
          import.meta.env["FLEET_FORMS_VEHICLE_TYPES"],
        ),
        "aa814bf7-a087-4378-934f-9374140551cf":
          import.meta.env["FLEET_FORMS_ENV"] === "testing",
      }}
    >
      {children}
    </BaseFlags>
  );
};
